import React from 'react';
import { Blog } from '../../components';

export default function joseph_proietto() {
    return (
        <Blog.TextBlock>
            <Blog.Author>Interviewed and written by Erica Musgrove</Blog.Author>
            ‍<br/>
            <br/>
            <Blog.Text><i>Professor Joseph Proietto is an Endocrinologist specialising in Diabetes and Obesity. He is a world-renowned investigator of the management of obesity. Professor Proietto established the first public obesity clinic in Victoria at the Royal Melbourne Hospital and is now Head of the Weight Control Clinic at Austin Health. He is a senior specialist at the Austin Health Endocrinology Clinic. He is a past President of the Australian and New Zealand Society Obesity Society (ANZOS).</i></Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>1. To begin with, can you just give us a bit of a brief overview of your career path to date, and how you got to be doing what you're doing today?</Blog.Subtitle>
            <Blog.Text>“Well, back in 1968 I found myself in first year medicine at Melbourne University, much to my surprise. I did my clinical work from St Vincent's, and then I was working at St Vincent's. I guess at the back of my mind was the idea that maybe I wanted to be a scientist rather than a clinician. I made this known to my superiors and before I knew it, I had a project and I applied for a scholarship, which I got. Even though I only wanted to try research for a year, I was tied up now to do a PhD, which was four years.</Blog.Text>
            <Blog.Text>I did that at St Vincent's. It was interesting. Up to that stage I'd been doing general medicine, and this was on the endocrine topic studying diabetes, which I didn't particularly like as a trainee or general physician. I then discovered that really everything is interesting, and whatever you get into, it’s the science and the questions that you're trying to answer that is critical. Having done my PhD I really got the research bug and there was no doubt in my mind that I wanted to be a researcher, with a little bit of clinical interest. That's essentially what's defined my career. All throughout my career I've done half a day of clinical work and then the rest of the week has been research.</Blog.Text>
            <Blog.Text>Following on my PhD, I did a post-doctoral fellowship lasting two and a half years in Switzerland, in Geneva. By that stage, I was married and had three children, which meant we were very poor. That went okay, and then we came back and I went to the Department of Medicine at Royal Melbourne Hospital, where I set up techniques to study glucose turnover. We were studying Type 2 diabetes essentially. I set up a technique to measure glucose kinetics during an oral glucose tolerance test, and then we also started work doing basic research in collaboration with others. In the end I was running a research unit that had both a basic science and clinical medicine component. We made quite a few transgenic and knockout mouse models of diabetes. Then increasingly we did some clinical work in diabetes. Then it occurred to me that really that diabetes was being driven by obesity. I started getting interested in obesity and we shifted our emphasis from diabetes to obesity, because if you conquer obesity, you've conquered Type 2 diabetes. Since I've retired from the university, I've come back to clinical medicine. Now that I can't do research, I'm now working four days a week as a doctor. I've gone back to my medical roots.”</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>2. In a nutshell, how would you explain, the physiology and neurobiology of obesity and what you found doing your research?</Blog.Subtitle>
            <Blog.Text>“Well, first of all, there's very good evidence that weight has a genetic basis both for diabetes and obesity. That has come from several studies in identical twins, both in terms of showing that identical twins are very much closer in weight than non-identical twins, and that identical twins are exactly the same in terms of degree of similarity, whether they reared together or reared apart. If you overfeed forcibly identical twins, some put on weight and some don't, but the twins track together. Then, finally, a study conducted in Denmark took 540 people who had been adopted as babies and divided them into four weight categories. Then they asked a question - is there similarity between these people and their biological mothers? The answer was yes. Statistical significance 0.0001. And there was a correlation with their biological fathers, a bit less, but it was still significant. What about the adoptive parents, who were the ones who fed them and brought them up? They found no relationship at all. That tells you that the environment is necessary, but not causative. The cause is the genes. The environment is the facilitating medium if you like.</Blog.Text>
            <Blog.Text>I guess our most well-known study is the one that showed how weight is defended in a way that the body never gives up. I like to tell my patients, we all have 10 hormones in our blood that are made by the small bowel, by the pancreas and by fat. Of these ten, there's only one that makes us hungry – ghrelin - which is made in the stomach. In Japan they found that following weight loss the level of ghrelin went up. Then, it was discovered that leptin, the hormone from fat that suppresses hunger, following weight loss its levels dropped.</Blog.Text>
            <Blog.Text>We had shown in a study we did that cholecystokinin (CCK), which is one of the hormones from the upper gut that makes us feel full after we eat, actually goes down after weight loss. In that study, we also demonstrated that if the patient is ketotic, the CCK doesn't go down. That was interesting. Then we did our own study where we measured not only those three hormones, but the others as well, and found that they nearly all changed in a direction to make you more hungry - so the ghrelin increases, the others go down. Then we found that a year later that those changes were still there. Then in another study we did on the rate of weight loss, we followed the patients for three years. Even though they had regained most of the weight, the ghrelin was still higher and the leptin was still lower three years after weight loss. Now we know that these changes are long lasting. There's some evidence that some of the changes are still there six years after weight loss, including leptin. That to me says that these hormone changes are permanent until you regain the weight.</Blog.Text>
            <Blog.Text>That's then changed our practice, because patients need to be helped in maintaining weight loss. In most cases, not all cases, this involves having some medication as well as some lifestyle advice about being mindful about what you put on your plate, about exercising, about weighing yourself regularly. I tell my patients if you gain just a little bit, just two kilos, then you need to go back on the diet to lose it quickly. Because if you don't, then each time you see the patient, it may only be a kilo, but that tend to build up and after a few years, they're back to baseline.</Blog.Text>
            <Blog.Text>Then we did this study on the right way to lose weight and disproved the myth that had always been taught that the quicker you lose it, the quicker you put it on. Not true. The regain was the same, but we found that the people that lost it more rapidly were more likely to succeed in weight loss because they had the ketogenetic diet. In between, we had discovered that ketones also prevent the rise in ghrelin. They have multiple effects to suppress hunger. Ketogenic diets are the way to go. They save the patient money by not needing to buy those expensive medications that suppress hunger.”</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>3. In your experience, what are some of the big myths that you wish people didn't believe about weight loss?</Blog.Subtitle>
            <Blog.Text>“Well, the myth that it's all due to lifestyle, that really makes me cross because then you blame the patient. If you're hungry, I think it's unreasonable to expect patients to not eat when they're surrounded by food. Yes, there are those who can maintain weight. I have a handful of them. Ultimately what happens is if you focus on your weight issue, you can overcome this hunger thing. The problem arises when something happens to your life, when you're distracted. Some unpleasant thing happens, and you become distracted. Your biology says, well, this is the time to get me back up there. It's a defence of weight. When we were hunter gatherers, this was what saved us. The fact that we didn't sit down, we went out and looked for food because the hunger was driving us.</Blog.Text>
            <Blog.Text>At the moment ketogenetic diets are all the rage, but people think they need to eat a lot of fat to become ketotic. It's not true. No. All you have to do is avoid carbohydrates. If you're trying to burn the patient's fat, it's illogical to me to feed the patient fat. Why not get them to burn their own fat? The problem is the body doesn't want to burn fat. The body wants to burn carbs. So, if you deprive the body of carbs then the body has to burn the fat.</Blog.Text>
            <Blog.Text>The ketogenic diet (for weight loss) is not long-term, because you don't expect the patient to avoid carbohydrates for the rest of their lives. What I do is I have them on the ketogenetic diet while they’re losing weight. The length of times you're on the diet depends how much they have to lose. Then we stop the ketogenic diet, and this is when I warn them about the increased hunger that will happen when they break to diet. So they're not ketogenic anymore during the maintenance phase and they can eat everything, but small portions. Here's where the problem arises. You tell them to have small portions and yet their hunger is increased. How is that going to work?”</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>4. A few years ago, your work was featured in ‘The Obesity Myth’ documentary series. What prompted, if anything, the creation of that series and what do you hope that viewers gained from it?</Blog.Subtitle>
            <Blog.Text>“Well, we didn't initiate a series. We were approached by the production company that SBS appointed to do a documentary on obesity, CJZ. They approached us and we agreed to do it. At the beginning I was a bit reluctant, thinking that we wouldn't get patients who'd be willing to expose themselves to the world like that. In the end we agreed because this was a big chance to fight that myth that's unfortunately still out there, that obesity is the fault of the individual. We agreed, and then to my surprise, there were no shortage of patients who were willing to share their experiences, which was fantastic. Of course, we never gave the production company the names of patients. What we did was we talked to the patients about the program, and then asked them if they’d be interested. We gave the contact details of the production company to those that said that they were, and then the patient rang the company.  Otherwise it wouldn't have been ethical.”</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>5. What do you think it takes to find that perfect niche within medicine, when there are so many possibilities? How did you find yours?</Blog.Subtitle>
            <Blog.Text>“Normally I would have said go and follow your gut instinct. I fell into diabetes by accident. When I was registrar at St Vincent’s, the medical registrar was in charge of the newly formed endocrine ward. I had a first-year intern and a second-year resident under me, and I remember distinctly saying to the resident, “I’ll do the ward round with the intern to see the general medicine patients, and it's your job to take care of the people with diabetes because I'm not interested in diabetes… Famous last words. If you'd have told me that day that I was going to spend the rest of my life researching diabetes and its causes, I would have told you, you were crazy.</Blog.Text>
            <Blog.Text>So, it doesn't really matter, in a sense, if it's not just your gut feeling. You've got to like the subject you're doing. I personally have never been good with my hands. There was absolutely no doubt whatsoever in my mind that I was going to be a physician and not a surgeon. On the other hand, there are people that really liked tinkering with stuff, who would be much better off being surgeons rather than physicians. You've got to look at your own likes and dislikes and passions to then guide you into where you go. All I can say is that, if you've got an inquiring mind, it doesn't really matter which field of medicine you go into, they're all interesting. I'm sorry now I didn't do neurology, because the brain is such a fantastic machine. I would love to have been a neurologist. I also like kidneys. I like it all. I nearly became a haematologist. It's really about you finding interest in your topic.”</Blog.Text>
            <br/>
            <br/>
        </Blog.TextBlock>
    )
}