import React from 'react';
import { Blog } from '../../components';

export default function melanie_tan() {
    return (
        <Blog.TextBlock>
            <Blog.Author>Interviewed and written by Erica Musgrove</Blog.Author>
            ‍<br />
            <br />
            <Blog.Text><b>Erica:</b> Welcome Mel and thank you for joining us today on Eternal Possibilities.</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Melanie Tan:</b> Thank you for inviting me.</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Erica:</b> It's kind of funny because obviously I met you as my EP (Ethical Practice) tutor, but then I did a Google search this morning to find out what your actual qualifications are and I was very impressed, and felt a bit intimidated at the same time! For those who don’t know, you were a doctor and now you are working in law. Let’s start with the medicine. What initially drew you to medicine? Tell us about your training.</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Melanie Tan:</b> It’s a bit circular, but basically, I’ve always been a much more humanities-orientated person, which is why I love things like EP and all that. When I was growing up, I was always into the arts. I always thought I was going to be a lawyer, and I loved writing, and I loved that analysis. Then, at the age of 16, I suddenly had this bright idea, because I love talking to people as well, that I wanted to be a psychiatrist. Basically, that’s why I did medicine, because I wanted to be a psychiatrist, not for any other reason.</Blog.Text>
            <Blog.Text>My dad is also a paediatric surgeon. People always ask me if he influenced me, or if my parents made me do medicine. I say, no. The only influence my dad had is the fact that he loves his work so much. He used to take me on ward rounds, and everything. He’s really passionate about his work, so that rubbed off. Other than that, I just had it in my head I wanted to be a psychiatrist and that was decided. I think also, at school, they had a moot court in year 11 or year 12, and I found it really boring, and so I did medicine.</Blog.Text>
            <Blog.Text>My first year of med, which was all pure science in those days - chemistry, physics, all that stuff - I hated it. I was thinking about actually switching back to law after my first year of medicine. But I didn’t want to keep switching back and forth, and I knew it would be different once I started seeing the patients. I convinced myself to stay on, and just stayed on the treadmill and never got off basically. I've never really enjoyed it; the study. I was the slackest student ever. We used to cram for exams and I just wasn’t really that engaged. I never thought about it again until my fifth year of medicine when we were doing our paediatric rotation, and everyone was saying, "This is the hardest rotation ever." So, I really buried myself in it. We were at the kid’s hospital then, and my dad had been there, and everyone knew my dad, because he’s a paediatric surgeon, and so I put a lot of pressure on myself during that term. I basically absorbed myself in medicine and just studied. During that time, I realized I don’t love medicine enough to do what I have to do, to do it justice. I’d also been thinking about what I wanted to do with it, and once I did my psychiatry rotation I thought “I don’t want to do this.” Academically, it was the most interesting subject out of everything, but I realized that I didn’t want to be a psychiatrist because it would do my head in.</Blog.Text>
            <Blog.Text>Once I made that realization, I thought, “You know what, why don’t I go back and do law, once I finish?” Because I was already in my 5th year, so I thought I’ll finish it, and then go back and do law, and then that will be my specialisation, to combine the two. I had that epiphany too in my fifth year. I was at the Children’s Hospital in a room studying there when I had that epiphany. I suddenly felt a lot better. As soon as I made the decision that I wasn’t going to be a doctor, I felt this weight lifted off my shoulders. Actually, I think it took me a while to get to that decision about law because I was processing that I’ll finish this degree, but I won’t be a doctor.</Blog.Text>
            <Blog.Text>So, I finished it, did my internship at The Alfred Hospital just because I wanted something different after my clinical years at the Austin. While I was in The Alfred, that’s when I applied for a Masters in Health and Medical Law at Melbourne Uni. Then, I also applied to Cambridge to study law there. When I got into Cambridge, I dumped the Master because I actually wanted to be a lawyer. The reason I applied to Cambridge was because when I was studying medicine, when I thought I wanted to be a doctor, I had always planned to go over to the UK to work for a few years after I finish. Again, this was something different. I’ve always been a bit nomadic by nature and a bit restless. I always had a plan to go to the UK to work as a doctor when I finished. Why the UK? Because I didn’t have to do an exam in those days, I could just go over there and work, as opposed to America. When I decided I didn’t want to be a doctor, I thought I still want to go there. And I thought it’d be really cool to go to Oxford or Cambridge or something like that, and that’s how it happened there.  </Blog.Text>
            <Blog.Text>I finished my internship in 1997, and in 1998 the term started in October at Cambridge, so I just spent the first half of that year locuming. Then I started in Cambridge in ‘98. I never knew when I set out to do law what I was going to end up doing. I knew I wanted to combine the two. I was at Cambridge for two years, and then I qualified as a solicitor in London. Then after that you do a year where you do a college of law exam, the practical qualification. Then I did two years training in law in London, and sort of at that time started doing medical negligence, and then I came back home after that. That’s how I ended up here, but there’s a lot more that’s happened since then.</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Erica:</b> Once you got into that process of doing law at Cambridge, did you love it? Did you finally realize what you wanted to do?</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Melanie Tan:</b> I loved it so much! It was crazy, because I also put a lot of pressure on myself too, because I was doing something that I really wanted to do, but I really loved it. I loved the study. I loved thinking about it. I remember I’d be sitting around with all my law friends, and we’d be talking about law, talking about cases. I would be so excited, so engaged. I loved talking about it. Whereas when I was in med school, and we’d sit around the dinner table with my med friends, talking about med, I used to walk off because I would be so bored. It was such a difference because I had those two things to compare with.  </Blog.Text>
            <Blog.Text>When we were talking about law, I just felt really into it. I remember reading judgments. The judgments in England are just beautifully written compared to the ones in Australia. I can't put my finger on it, but they are. Anyway, I used to read these judgments and get towards the end of the judgment, and literally go “Yes!” That’s how excited I was about it. I loved it. Some subjects more than others, but I did.</Blog.Text>
            <Blog.Text>I loved writing, but it was a challenge at first. In my first year in law school when I had to write something, I thought, “Jeez, it is really hard to even write a sentence after six years [of medical school].” It was interesting getting back into that. I’ve always loved writing.</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Erica:</b> That was actually going to be one of my next questions. I found out that you also do some freelance writing, and I read a few of your pieces this morning. What do you love about writing, or has it just always been there?</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Melanie Tan:</b> I do love writing. My intention has been to do my own freelance writing. I haven’t really done any paid freelance writing. So, I’m not a freelance writer, per se, but I’ve done a lot of writing in my various roles, especially the current one. I would like to be. I just haven’t had time to be honest.</Blog.Text>
            <Blog.Text>But I’ve always just loved it. When I was little, at school, I used to love writing stories. One of my ambitions was always to write a book. I did start doing a PhD, but I did not finish that for lots of reasons. When I was younger, I was more creative. I love being able to express myself. I love being able to deliver a message clearly. I write as a lawyer all the time. I enjoy the analysis that’s involved with it and then creating an answer.  That’s the thing with law compared to medicine as well, for me, was I found medicine was very algorithmic. It was flowchart thinking. I missed being creative because of this arty part of me that’s always been there. With law, it’s actually very creative in a way. There is an outlet for that because of the analysis, because it’s never black and white, because there’s always different arguments to look at. I enjoy being able to adapt what you write according to the audience, or according to the purpose.</Blog.Text>
            <Blog.Text>I just find it fun. I think there’s some satisfaction in creating written piece from scratch. You probably as a writer yourself know where you must start with a draft and you keep working. For ages you don’t like it. Then it just comes to a point where you feel you’ve got there. It’s a really satisfying feeling when you’re proofreading your own work. The first few times takes ages because of this and that. Then you know when you get to the end of it by how it feels. The whole process of writing I find very satisfying.</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Erica:</b> In terms of your creativity, obviously, you said you have a creative streak. I also know you’re in a band – a jazz band. Tell us about that and your musical side and how that’s evolved over time.</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Melanie Tan:</b> At the moment, I sing a bit of jazz. I’m not in a band, per se. I work with different people, like a guitarist or a pianist. I’ve just always loved music. Again, going back to when I was really young, I always loved performing. I don’t love performing anymore. It was a weird thing because I’ve become quite introverted as I’ve got older, but there’s these things that I still love. I remember my very first memory is my dad, he used to put on these hospital pantomimes and musicals and things like that. My dad is very musical, he plays piano, drums, sings. He used to get my brother and I up on stage who sing and stuff like that. He used to get me to sing with him on the piano. We were always listening to music, jazz especially, which is what I grew up listening to. I grew up around it. I just love it.</Blog.Text>
            <Blog.Text>Then, I think when I was in grade three or four, I remember just writing a musical. I wrote the script for a musical. I got two other friends together and I just wrote the script and the songs for it. I was the main part; I think I was called Amelia. Just for fun, I told my teacher about it, and the teacher got the whole class to watch our little musical with three of us singing and acting.</Blog.Text>
            <Blog.Text>I was also learning ballet and things like that. At school, I was always heavily involved in drama and things like that. Like every other Asian kid, I did piano, but we were moving around so much, it was always so stop-start. I did up to grade two maybe and I hated my teacher, so I stopped. I learned the cello for a little while because I wanted to learn to play this song called ‘The Swan’. I learned to play the song and then I quit, traded my cello in for a drum kit and played that for a bit. I love the drums, but I didn’t have the time to play it at the time because I was doing my internship. I never really stuck to an instrument, to my regret. I’ve also always liked singing, but never really got into it seriously.</Blog.Text>
            <Blog.Text>Basically, when I came back to Melbourne (the second time), I told myself I was going to learn to sing jazz. We’ve got an old friend, Noel Mendoza, who we’ve known for 30 years or so, he’s an amazing jazz pianist. All his kids are really talented musicians, and singers. I told myself when I came back to Melbourne from London that I’ll have lessons with Noel, and that’s what happened. I started lessons with Noel and then I just loved that. Then, it got to a stage where I felt like I needed to do a little bit more to push myself because with singing it is very different doing it in your own home or in the lesson than performing. I just gradually started singing in public. At the start I used to completely panic every time I had to sing in public. I did it to get over that panic. The reason I do it is really more for learning and self-development rather than performing I suppose. It’s all part of the learning. That’s why I do it. I love it. At the same time, it still scares me. That’s why I try and learn on the job. Gigs are a lot harder these days to get than in the old days. You don’t get those residencies like you used to anymore.</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Erica:</b> To take a bit of a turn, obviously I met you as a tutor at St Vincent’s. Your interest in education, was that relatively recent one, or have you been doing that for a while?</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Melanie Tan:</b> I’ve been doing that for a while as well. When I finished qualifying as a lawyer in England, I came back home to Australia. As I said, I never knew how I wanted to combine the two (law and medicine). I just knew I wanted to. I didn’t know whether I wanted to be a lawyer in private practice, or in government, or in academia. Basically, I tried everything. When I came back from the UK, the first time, I wasn’t qualified in Australia yet. I needed to take a step back. I had this clean slate with these two qualifications and I wanted to take some time to decide what I actually wanted to do with them. I also freaked out about potentially losing seven years of my education and training, so I went back to medicine so I wouldn’t lose it. I did some locum work while I decided what to do.</Blog.Text>
            <Blog.Text>One of the things I did during that time, amongst other things, was teaching through people I met. I designed and taught a course in Health Law at Flinders Uni because my parents were living in Adelaide at that time. Then at the same time, I was also teaching ethics at a Monash Uni tutorial. Then I also met a lady at Deakin, who later became my PhD supervisor, who is an academic lawyer, and she was running a health and biotechnology law course to final year law students at Deakin. She taught the health law component. The person who was supposed to teach the biotechnology law component wasn’t able to do it, so she needed someone at the last minute to come in and write and teach that course. That’s what I did. I did those three things all around the same time, as well as other things at the same time. That’s when I first started doing that. I enjoyed it. I love teaching.</Blog.Text>
            <Blog.Text>I realized then, and this was confirmed by the PhD experience more recently, that I didn’t want to be a full time academic, it would have also done my head in. I enjoyed doing a bit of it. I didn’t do the PhD because I wanted to be a full time academic. It was because I had stuff I wanted to write about. I just wanted to write basically.</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Erica:</b> One thing that I’ve always wondered about, particularly with you, as you wear a lot of hats and you have a lot of interests and you do a lot of things and you seem quite busy. I guess my question is where the concept of balance fits into all that? How do you manage to keep multiple interests going at quite a high level at the same time without hopefully burning out?</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Melanie Tan:</b> It took burning out to learn how to do it. I probably burnt out two or three times in my life, but I’m always very conscious of it. The most recent one I suppose was around the time that I gave up my general registration as a doctor and went non-practicing, which was at the end of June 2018. The first six months of that year, I was locuming in ED, I was trying to do this PhD, I was working in a commercial law firm, and also helping them to build a practice in health law in the commercial space, I was teaching then too, and I started trying to do the music. Basically, I remember for those six months, I knew I was trying to do too much, and I was constantly stressed. Towards the end of that six months. That’s when I thought, “I just need to stop.”</Blog.Text>
            <Blog.Text>I always knew I was going to give up medicine at some stage, but I just was never ready to let it go. I reached a point then where I was ready to let it go. It was time to draw a line under it. I woke up one day thinking, “Okay, I’m going to not renew my contract after June.” I converted my registration to non-practicing registration, to resist the temptation of just picking up a shift again. It was a deliberate, and it was a really hard decision. It was really scary because medicine has always been there for me and it’s always been my safety net. I could just walk away from any job I wanted anytime because I knew I always had medicine. I could go back to locuming and giving that up was really scary. But I knew I had to do it, because I know it wasn’t good for me anymore. I didn’t feel I was good for patients anymore.</Blog.Text>
            <Blog.Text>After I made that decision to give up medicine, not long after that I thought, “I have to quit this law job as well." It also didn’t feel right for me. So, I quit that as well. I decided to take six months off sabbatical just to sit down and write the PhD to focus on that and get it out of the way so that I could move on with the rest of my life. I just took those six months off to focus on the PhD, do a bit of music and keep the teaching. That was the plan, but then my dad got sick during those six months, so none of that really happened. I just focused on family and have been since then, which is fine. I think it’s really about sometimes being very self-aware of how you’re feeling inside and allowing yourself to admit to the fact that you’re doing too much. I think I spoke about that in EP as well, it has always been self-reflecting and being conscious of what’s going on in yourself, and actually acting upon it and not ignoring it. I think that’s really important. Because if I kept going as I was, I would have definitely burnt out.</Blog.Text>
            <Blog.Text>I had to sit back and really realize what my priorities were. Again, part of the reason I quit medicine was because the more I tried to hang on to that, the more time I was taking away from what I was actually good at, which was law. The doctor thing was something I was hanging on to. The more I did that, the less good a lawyer I was. I wanted to stop being a jack of all trades and try to actually master something. It’s about recognizing what’s important to you and what your priorities are. Again, when my dad got sick, my priorities changed again. The PhD went way down the bottom of the list. I didn’t care about it at all. For me, family’s always been the most important thing, but sometimes obviously things shift, depending on what’s happening.</Blog.Text>
            <Blog.Text>Then there’s other things like music. Yes, that keeps me busy, but it’s also what feeds my soul. It’s what helps me. There was another period, back in 2016, where I had a really, really stressful year as well. I was doing a bit of music then, and I felt that music saved me during that time. It still does. I still stand by that. I think now, for me, music really helps me. I recognize that it’s an important thing to keep up. Even though it’s not as important in terms of income or whatever. I’m never going to go anywhere with it, but it feeds my soul.</Blog.Text>
            <Blog.Text>There was another epiphany that I had a few years ago when I left medical negligence. As soon as it came to my head, I thought, “That’s so true. That explains everything. That explains exactly why I’ve lived my life as I have. Why I am who I am and what I do.” The epiphany was (this sounds really corny): I have the heart of a doctor, the brain of a lawyer and the soul of an artist. Because I love the day to day of medicine. I loved being with patients, helping people, that interaction and leaving the ED feeling as if I at least tried to help someone, whereas you don’t necessarily need that in other jobs. Anyway, so that’s what popped into my head and I thought that does make sense, because that’s how I feel. The heart part is why I love the medicine, why I couldn’t let it go. The brain part is why I love the law. The soul part is why I love the music. When I told my family, they said, “That’s right. That’s you.”</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Erica:</b> I think you just gave me the title for your piece. That’s perfect. I love it. Before you discussed how it was a really hard decision to finally leave medicine. What would you say to people who are maybe studying medicine, or are in medicine, who are having those thoughts of maybe this is not my life purpose, or I want to get out but I’m scared of leaving this thing that I’ve worked so hard to get to? What advice would you give to them? Is there a point at which you have to wait until you’re actually ready to finally make that decision?</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Melanie Tan:</b> I think you do have to be ready. Personally, I think that if I had left it any sooner, I probably would have regretted it. Look, I think that is to say I would miss it from time to time. I got to the point where I knew that I wasn’t going to miss it anymore. Having said that, I think with what’s been happening with this COVID stuff, this is actually the first time since that I wish I hadn’t. I feel like I want to be able to do more. I’m looking out to see what’s happening in that space now, and if I can actually convert my non-practicing back to practicing. Then I might do that, depending on what else is happening. Obviously, this is a very unusual circumstance.</Blog.Text>
            <Blog.Text>I spoke to another doctor who did the same thing as me and left clinical medicine quite early. He also used to locum in ED. He used to say, you just get little cravings for it every now and then. It’s just weird because, especially for us where our courses are a lot longer, the whole process was seven years, and we started a lot younger. It was really a big part of our growing up and part of our formative years. Plus, I grew up around it as a kid. I think, for that reason, it was especially hard to let go, because it was such a huge part of my life for so long.</Blog.Text>
            <Blog.Text>I think the main thing that I always say is, and as cliché as it sounds is it is really important to be true to yourself and honest with yourself, because if you’re not going to be honest with yourself, no one’s going to be. You’re the only one who can take action on that. I think that as a doctor, the skills that we learn as medical students and as doctors are so transferable across the board. Nothing has gone wrong. The experiences and the perspectives as well are so important and so unique that none of it will have gone to waste. Don’t ever think that. I think that I got into Cambridge law just because I had a medical degree. I think as a doctor, you’re highly employable to be anything you want to be. It’s just you got to decide what that is. I’ve always believed that if you want something badly enough, and you work hard enough, you will get there. It’s all about the want. If you don’t want it bad enough, you’re not going to try hard enough and you’re not going to get there.</Blog.Text>
            <Blog.Text>I wouldn’t be afraid of leaving medicine because if you can tolerate it enough, to keep it up a little bit, it helps in terms of job security. You don’t ever have to worry about not having a job. If you get to the end of the degree, and if you really, really hate it, don’t go ahead. Don’t worry about doing an internship. But if you think it’s okay, but I don’t love it, then I will probably suggest doing your internship. Because then you have the ability to locum, as a backup, so you don’t have that financial pressure. Also, talk to people if you’re thinking about leaving medicine. Don’t feel like you’re on your own because you’re definitely not.</Blog.Text>
            <Blog.Text>I just think it’s all about being honest to yourself. Not only what you like, but also what you’re capable of. This is why I always go on about self-reflection and having that insight into yourself. I think that’s really important. I don’t think anyone should be afraid of trying anything, giving anything a go. If you don’t try, you’ll never know. At the end of the day it is about doing your best. Not about doing other people’s best but doing what you feel like is best for you and what makes you happy.</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Erica:</b> Thank you. I think that’s quite a nice place to finish on. Is there anything you’d like to add?</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Melanie Tan:</b> The only thing I’d add is just to clarify what I actually do now: that I’m a health and aged care lawyer. Medicolegal tends to refer to what MDOs do, and there’s a little bit of that. In the health world basically, you have claims and complaints. There are medical negligence claims, and then you have other complaints or investigations that aren’t claims, like AHPRA or what not. But there is also regulatory work in the health space, so in health and aged care it’s about compliance, and I suppose a lot of it is about decision making, lawful decision making. It’s a different kind of area, it's more administrative government law.</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Erica:</b> Thank you, Mel.</Blog.Text>
            <br />
            <br />
        </Blog.TextBlock>
    )
}