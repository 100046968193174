import React from 'react';
import { Blog } from '../../components';

export default function sally_bell() {
    return (
        <Blog.TextBlock>
            <Blog.Author>Written by Erica Musgrove</Blog.Author>
            ‍<br/>
            <br/>
            <Blog.Subtitle>Firstly, can you tell us just a little bit about your career path in within medicine and how you got to your current position?</Blog.Subtitle>
            <Blog.Text>Yeah, I guess I'm one of those people who do not really know what they want to do at the start. I was a medical student at St. Vincent’s. I didn't really like staying at the public hospital. It looked a bit boring. I thought I'd be an intern in Geelong and do general practice. I did that, and I worked for two guys who were physicians in Warrnambool on country rotation and one of them was a gastroenterologist. I thought they had the most fantastic practice with all these really amazing bits. I thought ‘this is really good, I want to do what they do’. They said, “No, you don't want to do what we do. You should do anaesthetics, which finishes at six and then you go home”. Anyway, they wrote me my reference back to town. I got a job at the Royal Melbourne, which is where one of them had come from, on the basis of their reference.</Blog.Text>
            <Blog.Text>I spent three years at the Royal Melbourne doing BPT. Then I did my advanced training at the Austin. That was really nice, but it was too hard. I did a year at the Repat, which was very much a relaxed entry to gastro training. Then I did a year at the Austin, which was on liver transplant, the other end of the spectrum - completely chaotic, high stress, but good training. Then I came here to do some research based on the fact that one of the consultants here had said, "Look, come and do some something with us." The Austin weren't doing much in the way of research, and I didn't really feel finished after my advanced training.</Blog.Text>
            <Blog.Text>I did some lab work actually in the end. I'd come in thinking I was going to study alcohol and just talk to people about how much they drank. I ended up in a virus lab doing actual basic science. I was thinking, “Gee, I don't think I signed up for this”. It turned out that the lab staff were really, really nice. They taught me again about RNA and DNA, which I'd completely forgotten about. Now I have a group working knowledge of molecular biology. Perhaps somewhat disappointingly the hypothesis for my research was a negative, which was my first lesson in research. It was very difficult to get it published. It took a while before I warmed the concept of research again.</Blog.Text>
            <Blog.Text>I went overseas and did some clinical work overseas and that was absolutely fantastic. I would recommend that to anyone who was thinking of doing anything. It's just a good time in life. It seems like bad thing to do financially and from a family point-of-view. It is from those two points of view, but it's really, really good personally. I took my husband with me. He left his good job, and in actual fact he ended up with a better one and he got an MBA out of it. It was a race to see who had had the better time actually of the two of us. We had our first baby overseas. I had two years overseas and then I had a year off, and then Paul, who was the boss here [St Vincent’s] said, “If you're going to come back, you better hurry up and come back.” I came back and took up the job as a full-timer, which I didn't really realize at the time was quite rare. I was just lucky the girl who was supposed to take the job, married an Englishman when she went and did her research, so there was a job opening. I've been here ever since - 19 years.</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>I read somewhere that you have particular interests in IBD, liver cancer and nutrition. How did they come about?</Blog.Subtitle>
            <Blog.Text>Let’s go in reverse order. I came back and the boss said to me, “Well look by the way, I couldn't get all the sessions I needed for you, so I had to give you this nutrition session - you don't mind doing the intravenous nutrition for the hospital?” I thought, “What? I don't know anything about that!” Anyway, so I took on that and it's been a good way of getting to know surgeons and people across different silos. They're quite unusual, needy patients. It's quite tough but it's rewarding.</Blog.Text>
            <Blog.Text>As for the liver cancer side of things, we've always known that liver cancer has been increasing, and we just wanted to prove that it was more common than people thought. We had a fantastic liver fellow, Thai Hong, who's now one of the consultants here. Thai is pretty obsessed with Hep B, he’s very keen to make sure that this thing is highlighted. He did some fantastic research through the seven big hospitals in Melbourne looking at how many liver cancers there were and how they were tracked and showed that the incidence was about twice what it was thought to be. It worked because it was so fastidious and mythologically excellent. We got published in Hepatology, which was the premiere journal, so we’re pretty proud of that. That work has had ramifications. The Cancer Council changed the way they do things on the basis of Thai’s work.</Blog.Text>
            <Blog.Text>Then the IBD stuff... I worked in liver things originally, and I did IBD when I went to England, because I couldn't find a liver job in London that wasn't a lab job. I thought I'll just do this IBD stuff, this looks like fun. I was lucky, I worked for a guy called Michael Kamm. He's one of the professors here now. It was the era of the anti-TNF antibody, with the first trial in Crohn's disease. We ran two big trials and probably had the most English patients in the trial. I came back to Australia with more anti-TNF experience than anyone in the country, and experience with these incredibly complex patients. That set me up really in the IBD world.</Blog.Text>
            <Blog.Text>Then, because I'm a girl, I see lots of women. The first thing that happens when you make women well is that they want to have babies. Then randomly someone wrote to Michael Kamm said, “I've got a research fellow who wants to come.” But he couldn’t look after this person, so they came to me. Mette is my Danish research fellow, and she and I ended up doing this paper on babies who were exposed in utero to these anti TNF antibodies. We measured levels and their outcomes. It’s one of the studies that has shown it’s safe to take these drugs in pregnancy. The spin off is that I've got another fellow who's doing a PhD in that area now looking at other drugs. We run a pregnancy clinic here now, so that's become my ongoing research.</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>I was wondering if you could comment on your experience of being a woman in medicine, the challenges you might have faced, or what you think is great about being a woman in medicine.</Blog.Subtitle>
            <Blog.Text>Well, I think women make good doctors, that's the first thing. Actually, there are some studies that show that, although they're not widely publicized. I don't think it's just because we're touchy-feely, caring. We are good at the whole spectrum of medicine. I think it's good to be a woman in medicine.</Blog.Text>
            <Blog.Text>It's not always easy to get past that moment where you have kids, because then obviously you've got competing demands on your time. It just takes a lot longer to do anything. The traditional career path of a man will pretty much always outstrip that of a woman at that moment. That's a bit frustrating because you essentially have the same skills as they do, but you don't quite get there. You become Deputy Director instead of Director, or you miss out on something because you've taken a few years out, or you get given the slightly boring jobs because they finish at 5:00pm and people think you're a bit of a flake for thinking about your children. It's quite subtle, most of the bias against women.</Blog.Text>
            <Blog.Text>I mean I used to work slightly different hours so that I could pick up or drop off my kids, and people would ring you and say, “Where are you?” I'd say, “Well, I'm at pickup.” They’d think ‘What a flake. this woman is not even at work.’ But I'm answering your telephone call, just tell me what you want! Sometimes it’s irritating because you've got less time than the guys to do your stuff. They have the luxury of putting in an extra half an hour. Not that they think of it as a luxury, but it is luxury, whereas you have to leave because day care closes in five minutes and you will be fined if you don't pick up on time, or your nanny will resign, or whatever it is that you've arranged. I think that it just becomes logistically difficult.</Blog.Text>
            <Blog.Text>A lot of women are just grateful for what they're given in that circumstance and will not push the envelope. I think people get sick of it being a bit hard and they choose to make it less hard. They don't push through that period and hang around. They do often come back actually later, but it just takes a while. If you want an academic career, it's really difficult. I mean my colleague Barbara and I always call it the “mommy track”. There’s academic track and there's mommy track. Mommy track is the service track where you do all the work and somebody else gets the glory. If you're an ambitious person, then that's hard to rationalize. I think a lot of people give up their ambitions, or don't develop them when they could.</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>Now back to your career, you’ve just been appointed in a new role as Head of Gastroenterology at Monash (Medical Centre). Can you tell us about how that came to be?</Blog.Subtitle>
            <Blog.Text>I was Deputy Director here for about eight years, and then they changed it from a director position to a professor director position. This is an academic appointment at the university at the certain level. When it was advertised I did not have the academic qualifications to apply. One of my male colleagues, who's 10 years younger, did have the academic qualifications to apply but he did not have the management experience. In fact, he had no management experience at all. He applied and got the job. That was a really difficult career moment. I sat back and thought, ‘Now I have to work for someone who's younger than I am, who will outlast me here. What will I do?’ I could keep doing all my administrative stuff that I'd been doing for the previous guy, be the hand-maiden, while he's walking around being the professor, but instead I thought ‘You know what, I'm going to give this job back’. It was an unpaid position, extra to my normal job. I used the time to do research, that's where the pregnancy research came from.</Blog.Text>
            <Blog.Text>What that experience taught me is that men just go for it. Even if they don't have the skill set. Women think they have to have everything. I was told not to apply because I wouldn't get it, whereas if I had applied, I might have been in the running, maybe as an associate professor. Even if I hadn't got it, the experience of interviewing for it would've been helpful professionally. I think that was incorrect advice in retrospect. This time round when the directorship at Monash was advertised, I did have the qualifications, and so I have that job now, and I start on Monday.</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>How do you balance the demands of a managerial or administrative role with obviously caring for your patients?</Blog.Subtitle>
            <Blog.Text>I mean most people who are directors do about 50% clinical work and 50% administrative work. I do a day a week private practice and about 50% of the remaining time in clinical work. It will be the same at Monash. For the administrative stuff, I think you just have to be organized. You need a really good PA. This person is almost as important as your partner in making your life work. There's a reason that surgeons marry their secretary, it’s because they’re really important people and they see them all the time.</Blog.Text>
            <Blog.Text>People always think that admin is paperwork and boring shit. Yes, there is that, but I think what the administration offers is the ability to do the strategic planning work, to work out where the service is going, where the expansion should be going, and hopefully develop services that fulfil needs, and to change things. For instance, outpatients is done in the same way now as when I was a medical student, which is quite a long time ago. I mean, yes, now we have an EMR, that's probably the major change. Yes, we have a bit of tele-health, but not really. We haven't taken now that next leap. Part of that is a big picture problem, but part of that is that is has been driven by our suite of quite conservative practitioners who like the one-to-one. There's only so much you can do with one-to-one.</Blog.Text>
            <Blog.Text>There are other health services overseas where they do things differently, in a slightly more group or streamlined manner. You end up with the one-to-one, but you don't necessarily have to spend so much time. They have been forced to do that because of the sheer number of patients. I think it behoves us to look at those the ways people are doing it in those countries and look at what we could utilize. Hopefully that will be one of the things I’ll get to play with at Monash.</Blog.Text>
            <br/>
            <br/>
        </Blog.TextBlock>
    )
}