import React from 'react';
import { Blog } from '../../components';

export default function chandrashan_perera() {
    return (
        <Blog.TextBlock>
            <Blog.Author>Interviewed by Jefferson Tang</Blog.Author>
            <Blog.Author>Written by Erica Musgrove</Blog.Author>
            ‍<br/>
            <br/>
            <Blog.Text><i>Chandrashan is an ophthalmology registrar with a passion for combining healthcare with technology. He’s the Founder and CEO of Nebula Health - an agency specialising in patient engagement for private medical clinics. He’s also the Founder and Editor-in-Chief of the Journal of Mobile Technology in Medicine, a peer-reviewed journal examining the uses of mobile technologies in medicine. And one more hat to add for this talented man - is that he’s the Clinical Reference Lead providing clinical technologies advice to the Australian Digital Health Agency.</i></Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>Where did your passion for combining healthcare with technology come from?</Blog.Subtitle>
            <Blog.Text>Well, I've always been interested in technology since I was a kid, I always messed around with computers and things. Then I also got interested in medicine as well. It became a bit of a natural fit together. Before medicine I spent more time doing some techie things. After I was in med school and started working as a doctor, I saw that tech could really be used quite a bit in medicine and can be used to improve the way we do things.</Blog.Text>
            <Blog.Text>I've always helped out in various units with whatever tech issues there were, be that in spreadsheets, audits and things like that. My first proper real big meaty project was the journal, about technology in medicine. That sort of sparked my interest in getting involved in more big projects like that. It's where I learned about how to set up these projects and how to make them happen.</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>Could you tell us about the story behind the Journal of Mobile Technology in Medicine?</Blog.Subtitle>
            <Blog.Text>Yeah, sure. Initially the idea came about because me and one of my other friends, we were doing some research papers for ophthalmology. We were both keen on getting onto the ophthalmology training programs. What we did is we basically went around the hospital and essentially the aim was to check the accuracy of the various smartphone visual acuity apps. We wanted to know are they valid compared to a Snellen visual acuity chart, which is the gold standard? We thought this would be a great study to do. We basically just went around the ward, tested people with Snellen acuity chart, and also with an iPhone app as well. Then we also looked at the app store, because there's a whole bunch of different acuity apps. What we found was a wide variety of the quality of them, because the letters are supposed to be set in two sizes at certain distance. We picked one that at least gave the correct size and did that.</Blog.Text>
            <Blog.Text>We finished up that study and then we were looking at where should publish this. What we found at the time, this was quite a few years ago, when the app things or medical things were just starting to take off, there really wasn't a good place for it. They either tended to go to a small niche journal, or into some random tech journal that no medical people actually read. That's when we thought, actually this might be a good chance, a good opportunity to maybe try and foster some more research in the area. Also, rather than waiting for other people to provide that solution, why don't we just get on and do it? At the time, both me and my friend had done some other research stuff before as well. We floated the idea of starting our own journal, thought about it for a little while and eventually one day I was like, let's do it! We sat down, wrote out a business plan out - this is going to be how we are going to start; this is how we're going to get going, and maybe this is how it might look a bit down on the track. Then just started executing the plan to see what happens, and it worked.</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>A personal hunch I've had is that perhaps the mobile app space for health care is particularly over-saturated, at least in the last few years. What do you personally think?</Blog.Subtitle>
            <Blog.Text>I think it's just the bigger ecosystem of digital health in general. It's not really something new to medicine really with all of the tech stuff. It's just new tools to provide care to patients. Whatever that tool may be, whether that's something on a mobile phone or whatever it may be. It's just new ways of delivering and managing healthcare which previously didn't exist. Previously people didn't have the equivalent of a smartphone, that you can use to communicate with patients. That's something that we took on to Nebula, where really the aim of that start-up was around communicating with patients. I think it's a space that got pretty crowded pretty fast. I guess that's what happens with any new area, which is open for entrance from anywhere.</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>I'd love to learn the story behind Nebula Health:</Blog.Subtitle>
            <Blog.Text>Sure. After I was on the [ophthalmology] training program, especially after doing the general stuff, then I started reading more and more about start-ups, more just out of necessity of trying to work out how to run the journal. This is all while being quite busy working and being a reg and all that sort of stuff. After reading more about start-ups, I started to learn more about how to run the journal. I thought, this actually seems like fun and I really wanted to give it a go. I took a year off after my first year of ophthalmology training. I wanted to basically learn more about how start-ups work and figure something out.</Blog.Text>
            <Blog.Text>Actually, I got on to a start-up accelerator in Melbourne called Open Accelerator Program, together with one of my other friends, who's now an ENT surgeon. We were both quite keen on the digital health space. We decided to do something. We got into the start-up accelerator together with them and another development agency, because neither of us could do any coding and they were quite good. We got in initially with the idea of wanting to automate or standardize the way that healthcare is delivered in the hospital. Because what we saw was things are pretty segmented. If a patient comes in with a fairly straightforward diagnosis, there's a pretty predictable path that they followed through the hospital. A pretty predictable set of tasks that need to be done, and pretty predictable communication that needs to happen around the patient, which we thought was done fairly poorly, and could have been done a lot better. We actually started working with some units and started developing some software to try to address that problem.</Blog.Text>
            <Blog.Text>As we were going, what we learned was that as a start-up, especially in the Australian health system, dealing with hospitals as your main client was not an ideal way to do a start-up. It’s a very, very long sales cycle, and the problem with that is then as a start-up you want to be able to do is move in your rate quickly, which you can't really do that when you don't really get any validation. Or if you do, the validation you get is really limited until they [the hospitals] eventually end up deciding to try it or not.</Blog.Text>
            <Blog.Text>Towards the end of that year, one of the other problems I guess my colleague and I had personally experienced was around communicating with patients before and after surgery. Because often when you contact a patient, you send them to surgery, and they forget everything that you tell them. You see them day one, and then you might not see them for quite a while. Often the patients need to see something. We wanted to have a tool where basically you could have a video of your surgeon delivered to you at key points in time during your patient journey. Telling you, “Hey, you're about to have surgery tomorrow, here's what to expect, this is what the hospital will look like. I'll see you at surgery then.” After the surgery, "Hey, its day one, a bit of pain today is normal. Take the all blah blah blah medications. If you have any of these things, make sure you come back to hospital." Then another message might be saying, “Hey, it's a week since the operation. This is what's normal, this is what's not.”</Blog.Text>
            <Blog.Text>The idea is to reassure the patient, but also giving them relevant information in between follow-up visits basically. We found that that was something that surgeons really wanted and patients quite like that as well. So, we decided to change the tact of what we were doing. We got going with that, which was pretty good and pretty exciting. That's been running, and quite a few practices using it. We shifted a little bit in the way that we delivered that product.</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>What advice would you have for people who are interested in the digital health space?</Blog.Subtitle>
            <Blog.Text>Probably the biggest thing is just get involved and give it a crack in whatever way you can. If you're not sure and you’re just curious et cetera, then go along to some events. See if you can ask a couple of start-ups or people working in this space. "Hey, can I volunteer for a little bit, or can I hang out with you guys for a little bit and see what you guys do?" Most people are more than happy to teach and show you what's happening, if you’re somebody who seems to be actually interested. Then other things I think is good is if you're like, "Yeah, I got a bit of a taste for it and it looks fun." Then to actually try to get involved in whatever capacity you can.</Blog.Text>
            <Blog.Text>One of the hard things for medical people is time commitment. It's definitely quite hard to juggle the two. Even if you're able to do a project which maybe isn't starting a start-up that, for example, starting with something like a journal project or project you’re doing. The sorts of projects which you can do as an after-hours kind of thing. You can still make it work at some level and show it later on. If you start something that's always a good way to learn, as I find with most things in med. It's like an apprenticeship. You do it and learn.</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>If you could have a gigantic billboard anywhere with anything on it metaphorically speaking, getting a message out there to millions and billions what would it say and why?</Blog.Subtitle>
            <Blog.Text>Don't be in too much of a rush to get where you're going, and actually enjoy the journey along the way. Have a look around on the journey, because you might find there's another destination you want to get to. People that think that are enjoying something or think they might like to do something, great. Just test it out. Don't be afraid to just test stuff out. See if it works. If it doesn't, be ready to move on to try something else.</Blog.Text>
            <br/>
            <br/>
        </Blog.TextBlock>
    )
}