import React from 'react';
import { Blog } from '../../components';

export default function bronwyn_king() {
    return (
        <Blog.TextBlock>
            <Blog.Author>Interviewed and Written by Jefferson Tang</Blog.Author>
            <br />
            <br />
            <em><Blog.Text>Dr Bronwyn King AO is a specialist Radiation Oncologist, founder and CEO of Tobacco Free Portfolios, a former elite swimmer and Team Doctor for the Australian Swimming Team. Back in 2010 she met with a representative from her superannuation fund in the cafeteria of the Peter MacCallum Cancer Centre, looking to purchase a house and wanting to know how much money she had in her Health Super fund. She found out that her hard-earned money was being invested in tobacco companies – responsible for making the products which caused the devastation she witnessed and dealt with every day treating lung cancer patients. </Blog.Text>
            <Blog.Text>Forward ten years to the present and her not-for-profit organisation Tobacco Free Portfolios has assisted over 40 Australian superannuation funds and leading financial organizations in more than 20 countries to implement tobacco-free finance policies, diverting more than AU$20 billion away from investment in tobacco. She has been awarded numerous accolades for her tireless work, including Officer of the Order of Australia (AO) and Melburnian of the Year in 2019. She is keen to connect with medical students and listeners @DrBronKing on Twitter.</Blog.Text></em>
            <br />
            <br />            
            <Blog.Subtitle>THE SPARK FOR MEDICINE 💥</Blog.Subtitle>
            <Blog.Text>When I was 14 and training at the Nunawading swimming club gym, I saw a picture of all the muscles attached to the human body. I was completely transfixed. I couldn’t stop looking at the picture and trying to work out which muscles I needed to focus on in the gym to make me swim faster. I got a photocopy of that picture and put it up on my bedroom wall. That really sparked my curiosity about the human body and health and sport, and how important it was to be in good shape and to have a healthy life.</Blog.Text>
            <br />
            <br />  
            <Blog.Subtitle>THE ART OF RESILIENCE 💪 - <em>“There are so many things in life that have not gone as I would have wanted them to.”</em></Blog.Subtitle>
            <Blog.Text>In my teenage years I was an elite swimmer representing Australia at a junior level. My dream then was to be a champion swimmer for Australia. Instead I didn’t even make it to the trials for the 1992 Barcelona Olympics because I developed a serious shoulder injury the year before. That was devastating to me as a 17 year old. It was really, really disappointing. </Blog.Text>
            <Blog.Text>When I first got to university I found the style of studying challenging. I did medicine at Melbourne Uni and one of our first subjects was chemistry. The lectures would sometimes include more than 100 pages of references at the end. I thought you needed to know everything included in those references. By the end of semester I was only up to week two! So…I ended up failing my first exam at university. I got myself a tutor and he spent the first hour laughing at what I had done. He went on to explain that I only needed to know the exact content presented in the lecture. I turned out fine and finished the year with a H1 in chemistry. </Blog.Text>
            <Blog.Text>That experience taught me a lot about failure and about how you just have to pick yourself up and learn another way. In the end, as long as you look at it as a learning opportunity, it really doesn’t matter. I’m inherently very optimistic and very positive. I see possibility. I believe in things. </Blog.Text>
            <Blog.Text>Now with Tobacco Free Portfolios, the organisation I run, we have challenges all the time. It is very rare for anyone who’s executing any project to go directly from point A to point B. It’s almost a certainty that there will be hurdles and barriers, perhaps predictable but perhaps not. I have learned to perfect ‘the sidestep’ - just sidestep around it and keep going forwards. It might take some time. It might take you down a different path. It might challenge you enormously. You might have to draw on all sorts of resources and advisors and reconsider the foundation of what it is that you’re doing. If, however, you can keep your eye on the long-term goal, ultimately that will put you in a much better place.</Blog.Text>
            <br />
            <br />  
            <Blog.Subtitle>BATTLING CRITICISM FROM PEERS 🛡️</Blog.Subtitle>
            <Blog.Text>When I started Tobacco Free Portfolios it was just so fascinating to me that I found I couldn’t stop. I was really motivated to take it on because I had seen with my own eyes the injustice that tobacco inflicted on people. Then to think that people all over the world were unknowingly investing in these companies and only making worse the very problem that we were trying to fix…. it was something that seemed too big to just put aside.</Blog.Text>
            <Blog.Text>Early on, many colleagues thought it was silly and that I was being distracted from the ‘real’ work of research. I was told to reconsider what I was doing and that I was making a terrible mistake. When the very first super fund in Australia went tobacco free in the middle of 2012, there was a fantastic public announcement. It was a good news story for the hospital. It was a good news story for the super fund. Everyone was very happy. It was First State Super and they had got rid of $200 million worth of tobacco investments. </Blog.Text>
            <Blog.Text>That night I got a beautiful email from the Director of the lung cancer service at Peter MacCallum – Professor David Ball. He had been a mentor of mine for a long time and he wrote, “By this action you will save more lives than in an entire lifetime as a clinician. Keep up the great work.” For me, that was just the nicest thing in the world that anybody could say. It was like a shield because any time after that I received negative comments, I would think to myself, ‘if Professor Ball thinks it’s a good idea then it probably is.’ </Blog.Text>
            <Blog.Text>It really showed me the power of a mentor. For senior doctors out there, honestly, one sentence can totally change the life of somebody your junior. I think we need to be better at making sure we deliver compliments when they’re deserved and allow junior doctors to really blossom.</Blog.Text>
            <br />
            <br />  
            <Blog.Subtitle>THE METHOD BEHIND TOBACCO FREE PORTFOLIOS </Blog.Subtitle>
            <Blog.Text>I used my experiences as a cancer specialist to bring patient stories to life by telling them to senior leaders in the finance sector. It’s very rare for people to be fully informed about tobacco and to not do anything about it. I always kept the conversations very apolitical and very respectful. I just try to nudge people in the right direction. I had to learn a lot about when to push and when to just sit tight. Sometimes the timing was just not right, so I would just back off and wait a little bit. I had to be very patient, but I got support from within the sector, mainly in Australia first. </Blog.Text>
            <Blog.Text>Then when I started looking overseas, I had direct introductions at the very highest levels. People felt really good after working with us. Our mantra at Tobacco Free Portfolios is that we ‘name and fame’. When organisations choose to go tobacco free, we’re the first ones to offer them compliments. We might help them arrange an event so that they can get some great publicity from it. Ultimately it creates this win-win situation. They feel good, we feel good, and the whole global movement is driven forward.</Blog.Text>
            <Blog.Text>It’s been one of our defining strategies – people have constantly come back to us at Tobacco Free Portfolios and told us we were genuinely collaborative, we wanted to achieve a great goal together, and we wanted to work through any barriers and challenges together. They really appreciate that sentiment of positivity and community spirit. We’re extremely grateful for the great relationships that we’ve got in finance, which has meant that they’ve opened their networks to us. Not just in Australia, but now all over the world.</Blog.Text>
            <br />
            <br />  
            <Blog.Subtitle>FITTING IN EXERCISE IN A CRAZY SCHEDULE 🏃♀️</Blog.Subtitle>
            <Blog.Text>Exercise has been so difficult to fit into the crazy disrupted life I’ve been living. I’ve explored all sorts of options. For me the only thing I could do was bring the gym to me because I could just never get to a gym. My personal trainer actually fired me! She told me, “What’s the point? You keep cancelling on me because you’re away for this or that. It’s not going to work.” So, after that, I got a cross trainer, a stationary bike, weights, a Swiss ball, a yoga mat, that’s all at home and sitting right beside me in the office. I just try to do a little bit each day. I don’t need to do a two-hour workout every day. If I can just do 20 or 30 minutes a day, then that really boosts not just my physical, but also my mental health. I really encourage people to do whatever it is that fits in with their own lifestyles, whether that’s joining exercise groups or walking with a friend.</Blog.Text>
            <br />
            <br />  
            <Blog.Subtitle>ADVICE FOR JUNIORS: <em>“Just keep stepping forwards. The path is forming under your feet.”</em></Blog.Subtitle>
            <Blog.Text>Even though the COVID-19 crisis seems overwhelming to say the least, the world will get through it in one way or another. It’s pretty normal to have good days and bad days - everybody I know in business, government, diplomacy, medicine, sport is having good days followed by bad days. </Blog.Text>
            <Blog.Text>One of my colleagues in Tobacco Free Portfolios gave me this great quote, which I think will resonate with med students who are working out where they’re going in the years and decades ahead. It was about five years ago, when Tobacco Free Portfolios was just starting to expand overseas. I told my colleague, “Look, I don’t know where I am going with this.” She said to me, “Just keep stepping forwards. The path is forming under your feet.” I think it’s such a nice quote, because it really embraces that concept of uncertainty. I didn’t know exactly where I was going. I knew that I was being drawn to good things, and that the path was forming as I made it. You don’t have to follow a set path - you can create your own path. It’s also okay if that path has a few dead ends and you sidestep onto another route. </Blog.Text>
            <Blog.Text>I think that being a doctor is a remarkably rewarding and interesting and challenging and fulfilling career. I don’t think I’ve ever met a doctor who has regretted the years that they’ve spent as a doctor or in medicine. It’s one of the most interesting and satisfying professions I think anyone could ever have. It’s a wonderful career to have in front of you. I think you should really be open to whatever opportunities there are, but ultimately find something that really fits you. Find something that you love. The very best scenario is to wake up in the morning and feel energized about going to work. You should want to get there and you should feel great satisfaction in what you do.</Blog.Text>
        </Blog.TextBlock>
    )
}