import React from 'react';
import { Blog } from '../../components';

export default function katie_allen() {
    return (
        <Blog.TextBlock>
            <Blog.Author>Interviewed and written by Erica Musgrove</Blog.Author>
            <br />
            <br />
            <Blog.Text><b>Erica:</b> Welcome, Katie Allen, to Eternal Possibilities. Thank you for joining us. Let’s start with your medical career. What made you want to become a doctor in the first place and how did that take place and where did you study?</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Dr. Katie Allen:</b> Well, I grew up in a very medical family. My father was a doctor. My grandfather was a doctor. My great aunt was a doctor. My great uncle was a doctor. My mother, also my father and grandfather, both married nurses, so I grew up in a very medical family. When I was seven, I used to go on ward rounds up in Albury with my father and I loved it. My father loved his work. He just really cared deeply for his patients. He was very well respected, and he loved the study of medicine. I was embedded in a family that was about service to community through health.</Blog.Text>
            <Blog.Text>Then, I went through a stage when I was about 14, thinking maybe I was doing it because of family pressures, even though no one ever told me I should do medicine. I started thinking about being a lawyer or a journalist and all these other things. When I got to the end of school, I decided that in medicine you could actually do all of those things. You could be a medical journalist or you could be a medical lawyer or you could do research. I realized there’s so much choice within medicine, why not do medicine and then branch out afterwards? That’s why I decided to do that, which at that time was quite unusual. I think most people thought I wanted to do medicine to be a doctor. I had always thought about what else I could do as well. It was only later in life I realized that as soon as I finished medicine, I started doing medical research because I was trying to fix the healthcare system.</Blog.Text>
            <Blog.Text>I studied medicine at Monash University. I had always wanted to travel overseas, but my father wouldn’t let me. He kept saying, ‘Don’t break your school studies to go on a Rotary exchange scholarship,’ even though I won one, ‘because you want to get a good mark to get into medicine.’ Then I finished school, and he said, ‘Don’t take a gap year. You’ve got into medicine, get going with that.’ Finally, by the time I got to fourth year [of medicine], I was being a bit more canny about how I would try and encourage my dad to support me to go overseas. I looked around for an overseas exchange in research. At the time I was the President of the Monash University Medical Students’ Association, so I would sit on the medical faculty board. I remember asking people, ‘Do you know anywhere else where I could do a research year overseas?’ After about six months of asking, someone said, ‘Well, you could go and do an exchange year at Cambridge University. Would that be okay?’ I ended up brokering an exchange year at Cambridge at a time, when that never used to happen. It’s quite normal now, but it was a really great opportunity to spend some time overseas. I think that’s where I really got into research.</Blog.Text>
            <Blog.Text>I finished medicine and then started to specialize in paediatrics. Then I got into research as part of my Advanced Training and I’ve always done clinical work and research. I’ve never really done clinical work full time. I did research because I’ve been interested in not just responding to patients coming through the door, but trying to stop them coming in in the first place. That is what has driven me in my medical research career. I did three large research projects which were trying to enact change. One was on population genetic screening for haemochromatosis, and the genetics revolution wasn’t ready to do that, but I think we’re pretty close to it now. I made a 10-year contribution to that area, which was pretty satisfying, including ensuring that life insurance discrimination doesn’t happen here or around the world. I did some pretty innovative research to help in that area.</Blog.Text>
            <Blog.Text>I also worked for my PhD in liver cell transplant. I was specializing in hepatology, and because liver transplants were complex and risky operations, we were trying to work out a simpler way to do that. I undertook Australia’s first liver cell transplant, which was very exciting. It worked for a while, but then it failed. Then I basically did some more research to work out why it failed. We found that there was a different form of rejection that we hadn’t thought was a problem. We were the first to describe that in the world. Unfortunately, we shut the whole field down internationally, which was quite devastating having spent 10 years of my life working on that.</Blog.Text>
            <Blog.Text>Then I switched to food allergy because I was finding a whole lot of patients coming through my door, and it seemed to be this rising epidemic. I launched what I would say is probably quite a successful collaborative research agenda across the whole of Australia. We’ve already started to turn the tide back with the food allergy epidemic so that was very satisfying. I had a very satisfying clinical research career. I was regarded as a leader in the field. I had a large research portfolio myself. I had 30 people that I supervised every year to bring in grants for them, and was also a Theme Director. I was a manager for a research division of 400 people, and I sat on the Murdoch Children’s Research Institute executive. I was a professor at the University of Melbourne, a professor at the University of Manchester. I loved what I was doing, but felt that I was interested in what more I could do. Even though it was extremely satisfying, I still wanted to do more.</Blog.Text>
            <Blog.Text>Politics, I have to say, is the best decision I’ve ever made. It was scary because I was jumping into the deep end. I was really putting my whole career at risk, but I got lucky, I suppose.</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Erica:</b> Sounds incredible. You actually answered one of my questions, which was about your research and how that came about. You covered that pretty thoroughly. Before we move onto the politics, what was it about paediatrics in particular that interested you, if you can remember if you had a really strong reason?</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Dr. Katie Allen:</b> It is interesting because during my training at the Royal Melbourne, in the first six months of my internship, one of the cardiology directors said, ‘Would you like to do cardiology? You’re really very talented at this.’ And I said, ‘No, I want to do paediatrics.’ He said, ‘Why do we lose all women to pediatrics? You should stay in adult medicine.’ I said back to him, ‘Because there’s so much more hope in paediatrics.’</Blog.Text>
            <Blog.Text>Even when children are going through serious conditions, and some unfortunately do die, even when they’re dying of cancer, they’re still growing and developing. More importantly than that, the impact you have on one life can be profound for the rest of their life, but also on the family. The ability to influence a life trajectory, but also the life trajectories of those around that person and support them is quite immense.</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Erica:</b> Tell us about your transition into politics. You described it as jumping off a cliff. I can imagine it was quite daunting. When and how did that happen? Had you already had a foot in the door of politics or was it completely blind leap of faith?</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Dr. Katie Allen:</b> Well, as a senior researcher in Australia, you do have a lot to do with lobbying ministers and you have a lot to do with bureaucracies. As the Theme Director of Population Health, I initiated a Lunches with Government seminar series where I invited bureaucrats who would come to the Murdoch Children’s Research Institute. It’s better to partner with government because a significant amount of research funding is government contracts for institutes. I knew people in the area. I only joined the Liberal Party three and a half years ago. My mother was the secretary of the Liberal Party in Albury, where I grew up, but she died prematurely, and I never got involved. I do remember mum going off to events, but not really getting very engaged. I was busy through my 20s and 30s and even my 40s having a busy career and raising a family of four kids. The world passes you by when you’re right down the coalface.</Blog.Text>
            <Blog.Text>It was only when my kids were starting to get older that I became more politically aware and more aware of the fact that so much of what government does influences each and every one of us each and every day. I was really looking for what was my next research agenda going to be. One of them I was very interested in as a gastroenterologist, so someone who is interested in nutrition and food, was looking at obesity prevention. Everywhere I looked, a lot of the leaders [in that field] are really government leaders. A lot of the things I was becoming more interested in were across disciplines, and in public health. Public health is really government health. The whole concept of getting the ambulance at the top of the cliff rather than waiting for business at the bottom has always been something that’s been in my DNA. One part of that is being a paediatrician, because that’s the way we think. A lot of the big [public health] lifestyle changes have been made by paediatrics, discovering immunization or [improving] sanitation, and all those things. Paediatricians naturally think in a very preventive way.</Blog.Text>
            <Blog.Text>Anyway, if you really are into prevention, then the government is the place to be. One day I was speaking to the [Victorian] Shadow Health Minister, Mary Wooldridge, and she said, ‘Are you interested in politics or policy?’ I just responded, ‘Politics.’ She said, ‘Do you want to map out what you might want to try and do? I said, ‘Let’s do it.’ I had no idea what I was putting my hand up for, but I ran at the state level in Prahran, in Victoria. I had to run for pre-selection, which was the most daunting thing I think I’ve ever done. Because when you stand up at pre-selection, you’re being asked questions that you don’t know the answers to because there’s no textbook or guide about how to answer the questions. They could ask you things about politics from 20 years ago that I would have no idea about, or insight and knowledge about the party, about who was the president previously or what your view was on the Victorian state budget. These are quite complicated questions and it was a very rapid learning process of how to handle these difficult questions.</Blog.Text>
            <Blog.Text>I ran for the seat of Prahran in November 2018 where we lost the seat and the election and the next day I got interviewed by an Age journalist. He said, ‘Well, Katie, you’re in the Liberal Party. You just got wiped out. It must’ve been tough; you must’ve been bullied.’ I said, ‘There’s nothing further from the truth. I had a blast!’ They actually quoted me in the paper saying that, and the reason I wanted them to write that is because I want to have the opportunity to snowplow for others to follow. [At the time I thought] I might not get there, but we need more people who are evidence-based decision makers, who know how to deal with the public [who are] inside the tent. We need more scientists, doctors, engineers, people who are problem solvers. I actually think doctors make great politicians. I mean, as a doctor, my skill set includes dealing with the community from all walks of life. Some of them don’t agree with me. I’m helping them to get to a place of better health and outcomes that help them even if they don’t approve of the way I think about things. They still want solutions. That sounds like politics.</Blog.Text>
            <Blog.Text>As a medical researcher, I’ve had to bring teams together behind me for a common goal that I’ve decided this is how we’re going to get there. I need to bring volunteers and resources behind me to try and lead a campaign, and that’s the same as with medical research. Someone doesn’t give you a job, you actually have to have an idea and get the grants, get ethics [approval], get the project completed. Being up in Canberra is actually like being in an international conference. You’re dealing with people from all different backgrounds, from all over the country, just like when you’re at a global conference all over the world and you have differences of opinions that you need to try and find common ground. As a medical researcher, you do a lot of media, a lot of fundraising, and that’s what you do as a politician as well.</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Erica:</b> A lot of people have said that skill set is actually quite transferable to politics. Certainly, the people that I’ve spoken to previously, which I find quite interesting. In terms of your day to day work at the moment, obviously I imagine it might look a little bit different right now, what you normally do. What does your day to day as an MP look like?</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Dr. Katie Allen:</b> It’s funny. I asked that of Mary Wooldridge back when I was running at state level, because I was wondering what I was putting myself up for. She said every day is different, and that is exactly right. It’s a fascinating job. When I’m in Canberra, my day starts at 6:30am and finishes after 10:30pm. You start the day by reading all of the press clippings and being on top of what’s going on in the world. Then you might do a press conference because you’ve been put on the doors as a backbencher, so at the doors there’s a pack of journalists and we have to answer the questions for the day. Then parliament starts, and you sit in parliament while it opens,.</Blog.Text>
            <Blog.Text>There are divisions where the bells ring, and we have to run to the floor in order to vote. I give at least one speech a day in parliament. Sometimes I’ll give four in a day. There’s lots of different ways that you give speeches. There are constituent statements, there are 90-second statements, there are debates about bills, there are adjournment debates, there are grievance debates…there are all sorts of debates!</Blog.Text>
            <Blog.Text>Then, there’s committee meetings. I’m on seven parliamentary committees. I’m also on seven policy committees and I’m also co-chair of several Parliamentary Friends Committees such as Child and Adolescent Health, UNICEF, Preventive Health and Cancer Cures. I do a lot of committee work, which is again like academia, and fascinating. Some of the work that we do is really exciting, very interesting and makes a big difference. You meet with ministers, and you also have events where Parliamentary Friendship Groups bring an issue to parliament to try and advocate for a different outcome. There’s a lot of health things that are being advocated for, because every health group has an advocacy or support group, and that's usually tied to a Parliamentary Friendship Group.</Blog.Text>
            <Blog.Text>Every day there’ll be an event. [For instance] where someone talks about cystic fibrosis, and what can we do to improve testing and screening? There might also be someone who’s trying to raise awareness about lung cancer, how to get more funding and wellness practitioners to support lung cancer. It’s amazingly similar to my work, but instead of just being in paediatrics or in my area of gastroenterology, it’s right across the health spectrum. There are also similar committees in Parliamentary Friends in trade or in economics and defense. It’s just huge. It is like a water siphon, the amount information in there. I feel like I’ve just finished university, it’s my first job and I’m learning a lot very quickly. It’s very exciting. That’s when I am in Canberra, which is 20 weeks of the year.</Blog.Text>
            <Blog.Text>The other weeks I’m in my electoral office, where I am today. Basically, I go from community event to community event; visiting communities, community groups, going to Aged Care [Facilities], childcare, hospitals, schools, presenting flags, volunteer groups, giving volunteer awards, the Higgins Medal for School Kids. We’re about to do a Climate Solutions Tech Expo, doing a Senior Citizens Expo, just heaps of things in the community. I’m always being asked to cut a ribbon, give a speech, advocate for something, and then there’s a lot of constituent work. We have what we call clinics where people book in and I spend half an hour speaking to them about their issues, whether that be visa issues, Centrelink issues, what they want the government to do better or worse, those sorts of things.</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Erica:</b>  With so many pulls on your time and your energy, how do you go about prioritizing the things that you can make a difference on? And then perhaps realizing that you won’t be able to help every single person that comes into your office, I imagine that’s a challenge?</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Dr. Katie Allen:</b> Well, my number one priority is, every single person who comes to my office. So, the constituent work is prioritized. Like a doctor, if you don’t look after the patients, you can’t do the extra bits because that’s our core business. Every constituent gets an email or a phone call back, or a meeting and we will try to resolve the question by asking for ministerial support or by resolving it ourselves.</Blog.Text>
            <Blog.Text>The constituent work is actually deeply satisfying and extremely important. There’s a lot of it. I have staff to help me with that. The committee work is very large. There’s a lot of paperwork to read through, but again as an academic, I’m used to sifting through large amounts of paperwork, so that’s fine. In trying to advocate for the things that you’re passionate about, you’ve got to really just try and focus particularly on things like the Parliamentary Friends Groups. I like health and education, because I’ve been on the board of local hospital, a medical research and doctor, but also education because I’ve been the chair of the local school council. My strengths I think are science and technology. Anything that’s health, education, science and technology. My seat of Higgins cares a lot about the big issues, like climate action responsibility, and refugees, so getting very involved in some of the social agenda issues are quite important. I basically prioritize climate change, health education, science and technology.</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Erica:</b> Brilliant. It’s impossible not to ask a question about it, but how is the COVID-19 pandemic has affected your work and your role as an MP?  Editor note: this interview took place March 1st 2020, prior to the introduction of government social distancing measures.</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Dr. Katie Allen:</b> Well, we’re not going to Canberra, so that’s changed that bit, and I’m in the community and I’m basically more office bound. I’ve been going to maybe one community event a day, whereas before I’d go out to six a day. When I’m normally working, I’ll have two to three events in the evening. I go to none of those now, so in some ways, that’s quieter. The emails and telephone calls have increased, there’s usually three telephones running at once, and I’m getting about 1,000 emails a day. It’s just changed in the nature of the work. People are asking questions about the Prime Minister’s announcements. They’re confused and concerned, so we have to allay those fears, and give them the correct information. It’s very busy at the moment with just coronavirus because that’s the emergency we’re facing.</Blog.Text>
            <Blog.Text>But also looking long term for 6 or even 12 months when it starts to lift up, how to make sure that the economy is ready, and that people are ready for the businesses to reactivate. We’re already starting to think that far out. There’s also committee work continuing by Zoom or by teleconference. I’m on the National Redress Committee and we’ve been listening to witnesses talking about what happened to them 20, 30 years ago. We also have [other] Committee reports coming out, [ready for] signing off on. The committee work still continues. A lot of the work is reactivating, but online. Our priority is to make sure that coronavirus is being addressed first because that’s what’s concerning constituents and understandably, they want the information very rapidly so that they can make decisions for their lives.</Blog.Text>
            <Blog.Text>I’ve just been down to the Prahran Town Hall where Star Health has been commissioned by the Federal Government to start the first standalone pop up clinic for rapid access coronavirus testing. I went through as the first client, and I checked all their facilities and made sure they’re all fine. Someone was down there taking some photos, and we are very proud that we’ve got the first standalone clinic outside of GPs clinic for coronavirus [here in Higgins].</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Erica:</b> That’s really exciting. A slightly different tact here, but as a woman in both medicine and then in politics, have you ever faced any challenges or barriers that you felt were related to gender, and if so, how did you overcome that?</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Dr. Katie Allen:</b> Well, in paediatrics we are very female predominant, so I used to be the Equal Opportunities Officer to the staff. At the Murdoch Children’s Research Institute, because 80% of employees are women, I used to joke a little bit tongue-in-cheek that I was there for the men as the minority group. Not that I was, by the way, but [my role as EOO] was more about diversity. We were a very tolerant, diverse community in paediatrics. So, I often say to people, I grew up being strong in an environment where I didn’t see any of that [sexist] stuff going on. Very occasionally, I mean, one person when I was being interviewed, because I have four kids, and she was a female without children, she actually asked me, ‘Have you stopped having kids yet?’ Now, that’s really inappropriate.</Blog.Text>
            <Blog.Text>The sorts of issues I would come up against, I would say, are very subtle, which is why I’m a big believer in shining light in dark corners. I’m not frightened of what people tell me as long as they talk to my face, because it’s very hard to deal with unconscious bias that has no light shone on it. I’m a big advocate for free speech, of course, respectfully. Sometimes when people say things out loud, it actually makes it very obvious to others that they’re bigots, and then it can become quite obvious to them as well.</Blog.Text>
            ‍<br />
            <br />
            <Blog.Text><b>Erica:</b> I don’t think they were quite right either. It seems like you’ve had quite a rewarding career.</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Dr. Katie Allen:</b> Well, when I had two kids, they said that’s enough now. Then I had three, and they ask “haven’t you heard of contraception?” Then when I had four, well, that’s really end of your career because I always put my family first. It’s just me, because at the end of the day, [my family are] going to be holding my hand at the end. If you don’t have love, you don’t have anything.</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Erica:</b> That’s very, very true. Aside from your career, what other things do you like to fill your time with? As you said family is really important, but what about the interests, hobbies, passions that you do on the side?</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Dr. Katie Allen:</b> Well, I’m a swimmer. I love ocean swimming. We've got a little family getaway, down at the beach and when I’m down there I will swim 2km every day in the ocean. I love walking as a family, so we walk the dog and we chat, chat, chat. Anyone who is home we go for a walk at the end of the day, it might be 10 o’clock at night but we just get out there, talk and walk. My husband and I go to the theater. I’ve been subscribing to Melbourne Theater Company since I was 18. We love camping, we’ve been all over Outback Australia. We do love traveling, so we’ve been all over the world as a family. I’ve had a sabbatical overseas, long service overseas. My husband is English, so we tend to go home to his family. I love reading, just spending time with kids.</Blog.Text>
            <Blog.Text>A lot of my discretionary time was voluntary things. I’ve been a volunteer as a pro bono member of the Cabrini Health Board and a pro bono Chairman of the school council, which were things that I was very passionate about, but it also built skill sets. The day after I got pre-selected, I went in to work and on Monday and I resigned from the Cabrini Health Board. On Tuesday, I resigned after 28 years service to the Royal Children’s Hospital. On Wednesday, I stepped down as Chair of Melbourne Girls Grammar Council and handed it over to the Deputy. On Thursday we had two US NIH professors visiting, and I told them that I was handing over the [NHMRC] Centre of Research Excellence [in Food Allergy] to some young leaders that I had been supporting and developing. On Friday I handed over $6 million in NHMRC grants to other researchers in my team. On Saturday, I went back home to my family and said, ‘You're it!’ So, that was a pretty weird week, stripping back everything I’ve been working for, and then submitting myself to the process [of running as a political candidate in a Federal Election]. It was “maybe in 10 weeks’ time I’ll be elected”, or “maybe I'll fall flat on my face and I’ll just have to start all over again”.</Blog.Text>
            ‍<br />
            <br />
            <Blog.Text><b>Erica:</b> Fair enough. That sounds like a real act of faith, but it has obviously paid off.</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Dr. Katie Allen:</b> I’m lucky!</Blog.Text>
            ‍<br />
            <br />
            <Blog.Text><b>Erica:</b> In terms of advice, our audience is mostly medical students and junior doctors, many of whom are interested in the range of possibilities within their medical career and outside of a medical career if they choose. What advice would you give to them about a career in medicine, and then also those who are interested in politics as a potential future career?</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Dr. Katie Allen:</b> I’ll give you three pieces of career advice that has held me in good stead. To my father, I’d always say, ‘What should I do?’ It didn’t matter whether the question was should I do medicine, or even within medicine, what should I do? Because I always used to get so excited by different subspecialties. I wanted to do psychiatry, and then I realized I talk too much, and don’t listen as well as I should. I wanted to do surgery, but I realized that I’m pretty impatient. I would probably not be a good surgical personality. Anyway, the first piece of advice is what my father said: do what you love doing. If you love it, you’ll be good at it. If you’re good at it, you’ll always have a job. I think there’s a real element of truth to that. Nowadays the pop psychologists have some much sexier way of saying it, but follow your passions is a worthwhile thing.</Blog.Text>
            <Blog.Text>The second thing is don’t think that a pathway is clear from the outset. People rarely do follow a path of “I’m going to be X”. You want to be in a position where you have a skill set, you have a willingness and a readiness to try things, and then be open to opportunities. There were doors that I would knock on and hope to be, and I wouldn’t get a job, or I wouldn’t get a promotion, or I wouldn’t get a grant. I would knock a couple of times, because you do have to be careful to give it a good shake at the door and then realize this door was not opening for me. So, then I’d go and knock on another door. Sometimes I’d open that door and there would be Aladdin’s cave behind that door. I think being open to possibilities and opportunities is very important.</Blog.Text>
            <Blog.Text>In medical research, I learned a lot of resilience, because there’s only a 12% success rate. Making sure you’ve done a lot of thinking about what are the consequences of the opportunities is really important. With research grants, I used to say to people, I had 100% success rate, because you’ve only failed when you stopped trying. As it turned out, I ended up getting every single grant that I applied for in food allergy. Though when I’d been in a previous area, I hadn’t got any grants, so moving areas was actually obviously very helpful. When you find gold, you just keep on mining it, I suppose.</Blog.Text>
            <Blog.Text>The other piece of advice I would give is to seek advice widely. Be open to people who give advice. There’s lots of people who give you advice. You don’t have to listen to it [all], or you don’t have to follow it [all]. You should listen politely and be very gracious and grateful for any advice people give. Just because you’re listening to advice doesn’t mean you need to take it. You need to work out what’s right for you. The more advice you're taking broadly, the better quality of decision making you have because they’ll be able to see it from different perspectives. Sometimes the advice I would take would be 180 degrees opposite to what the advice was given. The fact that the advice was given gave me great clarity about why I wasn’t going to take that advice.</Blog.Text>
            <br />
            <br />
            <Blog.Text><b>Erica:</b> Brilliant. Thank you for that. I like the three distinct points. Thank you so much for taking time out to have a chat with me today. We really do appreciate and look forward to putting together your final piece.</Blog.Text>
        </Blog.TextBlock>
    )
}