import React from 'react';
import { Blog } from '../../components';

export default function david_lam() {
    return (
        <Blog.TextBlock>
            <Blog.Author>Interviewed and Written by Erica Musgrove</Blog.Author>
            <br />
            <Blog.Text><strong><em>Erica: Welcome to Eternal Possibilities. Thank you for joining us. I’m here today with Dr. David Lam, who is a GP Fellow and was the RACGP GP of the Year in 2019. We will start right back at the beginning. What made you want to become a doctor? </em></strong></Blog.Text>
            <br />
            <Blog.Text><strong>Dr David Lam: </strong>Well, if I am completely honest, I didn’t even want to be a doctor in the first place. Coming out of high school, I wanted to go to The Elder Conservatorium of Music in Adelaide to study jazz. But I was a bit unsure as to what career pathways that would lead me to thereafter, and so not really knowing what I wanted to do, I actually ended up signing up for law school. Did a year of that, and loved that, but for complete curiosity's sake, I also applied to and got into medical school at the University of Adelaide... and here I am. </Blog.Text>
            <br />
            <Blog.Text><strong><em>Erica: That’s awesome. Quite an unconventional path, I guess. From doing your medical studies, how did you find your way into general practice? Was that always something that interested you, or did it come about through a series of random events? </em></strong></Blog.Text>
            <br />
            <Blog.Text><strong>Dr David Lam: </strong>It was absolutely not what I always wanted to do. This is probably a pretty familiar story. Like a lot of young males in medical school, I wanted to do surgery. It seemed a logical choice. I’m keen on music and boxing. I like to solve matters with, in both of those areas, with my own two hands. I thought, why should medicine be any different? You have a bowel cancer? Well, I’ll chop it out with my own two hands. My surgical training plan at the time was essentially contingent on that stupid, childish unproductive side hustle of playing guitar evaporating when I finished medical school and became a grown up. Unfortunately, quite the opposite happened. </Blog.Text>
            <Blog.Text>As I went through my medical career, I found myself gigging more and more actually. By the time I was in my final year of medical school, I’d released an EP. In that year I had learned how to DJ as well and started actually play in the clubs in Adelaide. Then I started touring interstate, getting contracts with Port Adelaide Football Club to play at their home games, et cetera. Juggling that and the hospital shifts was really quickly becoming an unsustainable task. I was forced to make some hard career decisions. For example, there was no way I could tour if I was required in theatre all the time. One of the most underrated benefits of general practice, I find, is the flexibility. Now, I can tailor my work hours around tours and do what I want to do. </Blog.Text>
            <br />
            <Blog.Text><strong><em>Erica: I totally agree. What does your day to day look like, or maybe week to week, and what are the best and worst parts of your job? </em></strong></Blog.Text>
            <br />
            <Blog.Text><strong>Dr David Lam: </strong>Sure. It is a bit different from me compared to somebody who lives in a metropolitan area. I guess for the benefit of your followers not from South Australia: I live and work in a town called Port Lincoln, which is a town of 16,000 people. I guess the hardest part working there is that I am 650 kilometers away from the nearest major hospital. That means - and this is quite typical of a lot of South Australian towns – that the GPs working in those towns don’t just deal with the GP clinic stuff like their counterparts in the metropolitan areas, but they also look after patients in the hospitals. They do all the emergency department work as well, or certainly I do in Port Lincoln. We also look after the inpatients as well in the hospital. That means that I do spend a fair bit of work time day to day doing the medical stuff. The average day would start off with me doing a ward round with my registrars and the medical students and checking on our patients in the hospital first and foremost. Then I’ll go off to clinic and see patients in the clinic and sort them out there. Then I’ll usually end the day with a ward round to see how everybody has been going back in hospital, because unfortunately I couldn’t be physically there the whole day. </Blog.Text>
            <Blog.Text>Each of the doctors in our town take it in turn to be on call at the emergency department. If somebody comes in with an emergency, like a heart attack or stroke or broken arm, then they’ll call us and we will deal with that. On a civilized day, everything happens in a nice planned order, but it’s very rarely a civilized day. Because at the end of the day, if somebody is having a heart attack or stroke in hospital, I will literally have to stop what I’m doing in clinic and go sort that out. People are very good about it, particularly in the country. I will quite routinely say to patients, “Sorry, I’ve got to go. There’s an emergency. I’ll see you in four hours.” And they will very patiently wait that time. Because they have the trust in us that if it was them on the other side, in the hospital, then we would come straight to them. People in the country are very good about it. Certainly that does make my job as a doctor working in that area pretty tough. </Blog.Text>
            <br />
            <Blog.Text><strong><em>Erica: That leads quite nicely into my next question, about rural medicine. What do you like about rural medicine versus metropolitan medicine, and what would you say to people who are skeptical about doing medicine in a rural town, or maybe the lifestyle there? </em></strong></Blog.Text>
            <br />
            <Blog.Text><strong>Dr David Lam: </strong>Fair question. From a medicine point of view, it is just interesting as all hell! In like a town like mine, where I’m at least 650 kilometers away from any of the nearest specialists, it’s not just my town we look after. We’re one of the bigger towns in the area. People from towns of with even smaller populations drive over to see me. Because of that, you’re dealing with a whole lot of cool, interesting cases that you would otherwise not deal with in the city. Stuff like car crashes, strokes and heart attacks, stuff like very, very bad footy injuries. </Blog.Text>
            <Blog.Text>I think everybody should, at some point in their career whether that be as a student or a doctor, practice in a rural area, because of the experience. In terms of the skeptics, I think the two biggest misconceptions are: one, that you’re dealing with medicine that’s not relevant to the city. Then if you had to go back to the city, you wouldn’t know how to deal with stuff. I reckon it’s quite the opposite because again, you’re here and you have to deal with all of it. I found out that I was quite a bit smarter after coming off country rotations than the others. I think the other thing that’s worthwhile mentioning, which is arguably the best part about doing rural practice, is that because there’s not that many doctors or students there, the ratio of people looking to learn and people there wanting to teach is quite a lot better in your favour. That means that if you’re in a country town like mine, there’s eight of them (students), with 20 doctors ready to teach them. You get a lot of one-on-one mentorship that you otherwise wouldn’t in the city where the ratio is completely reversed, and there’s literally hundreds of medical students all following around the same consultant. </Blog.Text>
            <br />
            <Blog.Text><strong><em>Erica: Now on to the RACGP GP of the Year award that you were given last year. It sounds like a pretty incredible accolade. How did that come about? Did you know you were nominated, and what did it feel like to have your work recognized in such a way? </em></strong></Blog.Text>
            <br />
            <Blog.Text><strong>Dr David Lam: </strong>Thank you. I’m still not quite sure what happened. There’s a good 30,000 GPs across the nation, and I’m sure there’s far better ones than me out there. Technically speaking, looking at the fine print, it looks like they were judging people on their clinical pursuits, their commitment to the ongoing development of general practice, their commitment to medical education and to bettering services for patients and then also innovative ways to do things. To be honest, it was actually a secret nomination, so I still don’t know who nominated me. </Blog.Text>
            <Blog.Text>I do know that as part of the process, they often look for support from the local community or from your fellow colleagues, purely because I got a whole bunch of sneaky texts saying, “Just got asked to put it in a good word for you. I absolutely did.” It was really lovely to hear that there were lots of members of the community and lots of members of the profession that thought I was helping in a big way. It sounded like there were lots of different people, from high school kids that I have mentored and snuck into clinic with me to see how it all works, to patients, old and young, other GPs, or GP registrars that I train, medical students that I tutor, and nurses and allied health that I work with in the hospital. It was just really lovely to get that feedback that I was part of the team essentially. </Blog.Text>
            <br />
            <Blog.Text><strong><em>Erica: That does sound pretty fantastic, but also well deserved. On the teaching side of things, I heard of you through my friends at Adelaide who just rave about you as a tutor. What do you like about teaching and how did you fall into or come to that role of being a tutor and someone passionate about medical education? </em></strong></Blog.Text>
            <br />
            <Blog.Text><strong>Dr David Lam: </strong>By sheer accident. As a doctor you, not necessarily by choice, but me personally I love when it happens, you end up having medical students follow you around. You find if you give one medical student tips, they’ll tell the other medical students and all of a sudden you have multiple medical students following you around. What’s my mindset in teaching? Sadly, there’s been so many times in my career when I was made to feel like I was this big [small hand gesture] or not right for the job or would amount to nothing career wise. That just wasn’t fair. </Blog.Text>
            <Blog.Text>I think as medical students and doctors we’ve all been in that scenario, more than once in our careers. I just didn’t think that was fair because there was often no constructive criticism, just anger or apathetic disdain from your supervisors. Sadly, it’s no wonder that we’ve got such a high rate of workplace bullying and suicide amongst younger workers in our profession. I really think that needs to stop. My teaching really literally just revolves me going around saying, “Hi, students and junior doctors. These are all the times I got burned. Here are some simple tips on how to avoid it.” People might not have necessarily said it out loud, but had they, it would have been a completely different ballgame. </Blog.Text>
            <br />
            <Blog.Text><strong><em>Erica: Sticking with that theme of medical education, if you had a magic wand and you could change something about the way medical education was approached, what would you do? </em></strong></Blog.Text>
            <br />
            <Blog.Text><strong>Dr David Lam: </strong>Well, this is purely based on the feedback that I get from students, which resonates with me from back in time I was a student or a registrar looking to learn. I don’t know, how controversial do you want me to be? I think we are very much fixated in this day and age in medical education on ‘self-directed learning’. I think there are some absolute benefits to encouraging you to be independent and being independent in utilizing resources and coming to an answer. I think there’s a big common misconception amongst a lot of educators out there that students and junior doctors just want to learn only for exams, and they are lazy or distractible, or otherwise just simply incompetent. I feel like this couldn’t be further from the truth. While I do think skills in terms of learning how to come up with the answers yourself and testing yourself and how you reason is important, I also think there is a role sometimes for just a one sentence answer. We just need somebody with that clinical experience to say, “Everybody shut up, this is the one sentence answer, and there we go.” I really think that my big advice to medical educators out there would be if a student or registrar is asking you a question is usually because they have tried to look the answer up themselves and is not a really obvious in the textbooks, not because they’re trying to shortcut the system. I guess that would be point number one. </Blog.Text>
            <Blog.Text>The second point is, sometimes unfortunately we as a medical profession because we are all pretty smart people, at the same time sometimes I think we overcomplicate things. We do this a lot with patients. We use too much jargon. We make too many lists of things. There’s that old saying out there that if you’re going to tell a patient something in the consultation, keep it to five things, and then do the rest on another day. Because if you say more than five things, they’re not going to remember that, they’re not medically trained. They’re not going to remember that. I think the same thing is applicable to medical education as well, and just education in general. For example, when I’m teaching somebody to box or teaching somebody how to play a piano, I don’t overload the student in the first lesson. I don’t start with this is how you should be playing a Beethoven Sonata, or this is how you should do a complex 8 punch combo. That’s just ridiculous. It’s more important that we master these first one or two basic steps, even though they are basic, and then we go from there. </Blog.Text>
            <br />
            <Blog.Text><strong><em>Erica: I really agree with that and I appreciate it, because the amount of times I’ve tried to look stuff up and it’s not as simple as Googling it sometimes. </em></strong></Blog.Text>
            <br />
            <Blog.Text><strong>Dr David Lam: Absolutely. I think to that end, because this is how I’d start off a lot of my teaching with students, I would tell them, “Guys, I’m not a smart guy. I like lists of only five things and algorithms that say: if it’s this, then do this, if this then do this.” I really think we should start making that a thing and start keeping the knowledge to bite-sized chunks, rather than have a big exhaustive list from Google, which is not easy to remember. I think we should just basically make things just lists of fives: here are the five most common differentials. Here are the five red flag differentials that could kill a person if you miss them. That’s it. </strong></Blog.Text>
            <br />
            <Blog.Text><strong><em>Erica: Couldn't agree more. Outside of medicine, and you have touched on this already, so you’re a boxer and a successful and touring DJ. Can you tell us a bit more about those passions and how you make time for them around your career and around medicine? </em></strong></Blog.Text>
            <br />
            <Blog.Text><strong>Dr David Lam: I don’t know where to start. Arguably, parts of me live more for that than for the medicine part. Boxing is lots of things to me. Boxing is about fitness, boxing is about staying healthy and that’s why I encourage it. I want to clarify I’ve not had any fights myself, but I certainly train very, very regularly and have done so for years. It’s something I certainly advocate to all my patients. It doesn’t matter whether you think you can or you can’t, you should take up boxing. Not to fight, but for the fitness and the cardio alone, my God, it’s just so life-changing health wise. For me, boxing and fight sports are also about confidence. Giving you that confidence that I can make my body do something if I want to. That’s something that I’d like to instill in all the patients as well. I guess the final thing for boxing is about family. One of the big reasons I came back to Port Lincoln after being a student here was because the boxing club. They’re essentially my family. Pete Williams is the head coach of the boxing club there. He’s basically like my father. I really love that club because if nothing else, they treat everybody the same. It doesn’t matter whether you’re the best fighter in the gym, or whether you’re just there training casually. The coach knows everybody by name and gives you the time of day. That’s very much the doctor that I want to be as well. Basically, the human being I want to be. </strong></Blog.Text>
            <Blog.Text>The music stuff, well, that’s just always been a part of my life. I loved violin and piano when I was young, then the DJ-ing just happened randomly. Essentially, I was my final year of medical school, when my brother came up with this hare-brained scheme that we should learn how to be DJs off YouTube and start touring. To his credit, he was a big driver of that and made sure that we both knew how to DJ and we had the right gear. And here we are. Certainly for me, the music is a big part of my life. It’s just very much a way for me to be me, and for me to show and tell other people how I’m really going inside much more than any other part of me. </Blog.Text>
            <Blog.Text>I’d like to think that that’s partly why I get the feedback that I’m a decent doctor and a decent teacher as well, because I think the key to music, and to being a good touring DJ, is basically it’s all about reading other people and their reactions, or reading their body language and how they react to the way you’re playing. There’s a look in someone’s eyes when you play just the right banger, or when you play their favorite song. There’s a way they dance. There’s a way they just change. The not so great musicians and DJs don’t listen to people. They’re rigid, and they have their set list the way they want to do it and bugger everybody else. I think that’s silly. It defeats the purpose. </Blog.Text>
            <Blog.Text>Music is art, and art is meant to be shared by everyone. I like to think it’s the same thing with medicine. Being a good doctor is the same, it’s all about reading people and their body language, not just the words that are coming out of their mouth. It’s about reading their reactions. If you don’t do that, you only push your agenda on what you think should happen, what you think is going on, what treatment you would choose, without any regard for how the patient is feeling or what their hopes and concerns are. I don’t think you get anywhere. </Blog.Text>
            <br />
            <Blog.Text><strong><em>Erica: On a topical note, how has the COVID-19 pandemic impacted on you and your work? And what advice would you give to medical students who have had their year quite heavily affected by this situation? </em></strong></Blog.Text>
            <br />
            <Blog.Text><strong>Dr David Lam: </strong>Absolutely. I mean, it’s just affected not just medicine, but life in general. It’s just been very, very sad with a lot of the things that are happening despite our best efforts. I guess from a clinical point of view, two things. At the end of the day, there are plenty of cases in medicine where there’s just no substitute to physically examining the patient yourself, in person, in real time to figure out what’s going on. Obviously, with the social distancing, there’s many more cases now where the risk of making the patient more sick rather than less sick applies, so you can’t do that part of your job properly. The second part is the preventative care aspect. One of the best parts about me being a GP is sorting out the problems before they become problems. That means keeping an eye out and sorting out problems before they become unfixable. With social distancing, there are a lot of times where I otherwise would have investigated something or sent somebody off to get surgically fixed for something, but I can’t now because it’s not urgent enough to risk someone getting sicker from coronavirus. That makes things very difficult and sad from a clinical point of view. </Blog.Text>
            <Blog.Text>From an educational point of view, unfortunately, there is just no substitute for hands on experience. I think that’s the best part about clinical experience is literally you will be the one doing the procedure. As a student in Port Lincoln, that’s where I learned how to deliver baby, because I literally had to deliver the baby with my bare hands. That’s where I learned how to do an anaesthetic because I literally had to do the anaesthetic intubation under supervision with my own two hands. Unfortunately, that’s just not an option with the social distancing. It certainly had its impact. I just wanted to say on behalf of us all, I’m very sorry. There is no substitute for hands on clinical teaching, but we have to put safety first. We’re trying our very best to educate despite social distancing. </Blog.Text>
            <Blog.Text>This is my biggest piece of advice that I had even before COVID for junior doctors and students, and that is keep asking lots of questions. As the student, keep asking lots of questions. There are no dumb questions, so ask them all. Even as a junior doctor, the big misconception out there is if I ask lots of questions, everybody in the hospital is going to think I’m dumb, and they will think I’m incompetent. Well, to be honest, that’s not the case. Because as this medical student, or as a junior medical officer, everybody assumes that you don’t know anything, and you don’t know what you’re doing anyway. You don’t have to worry about that. It’s quite the opposite. If you ask lots of questions, even as a doctor as a junior doctor, people will actually find you conscientious and be more keen to teach you. It’s that old saying of, if you don’t ask you will never know. </Blog.Text>
            <br />
            <Blog.Text><strong><em>Erica: Lastly, what advice would you give to medical students and junior doctors just about their career in medicine in general and maintaining or pursuing interests that might be outside of that as well? </em></strong></Blog.Text>
            <br />
            <Blog.Text><strong>Dr David Lam: </strong></Blog.Text>
            <Blog.Text>My big advice would basically be don’t ever let anybody tell you that you’re not right for the job. If you’re there, you’re right for the job. Don’t ever let anybody tell you that medicine is life and life in its entirety and there’s room for nothing else. I thought that music would just wither away and die, but quite the opposite has happened. Last year as a full time rural GP, I somehow managed to also tour six different states in Australia with my music. It’s a doable thing if you put your mind to it. </Blog.Text>
            <Blog.Text>My final note would be that I’ve recently created a free nine-episode podcast called “GP Lyf Hacks”, and it is basically me revealing all my doctor trade secrets and tips. I’ve made it a point to cover all those areas that are difficult to find in the textbooks, and not taught as in depth because they’re not considered content by the uni. Stuff like which painkillers to prescribe, which antibiotics to prescribe, which scans to order and those types of things. I would just encourage anybody out there who is interested, feel free to get in touch directly with me with any questions. None of them are too dumb. Keep asking questions because that’s how you learn. </Blog.Text>
            <br />
            <Blog.Text><strong><em>Erica: That’s brilliant. Thank you so much for that. </em></strong></Blog.Text>
        </Blog.TextBlock>
    )
}