import React from 'react';
import { Blog } from '../../components';

export default function peter_blamey() {
    return (
        <Blog.TextBlock>
            <Blog.Author>Interviewed by Jessie Wu and Amber Anderson</Blog.Author>
            <Blog.Author>Written by Amber Anderson</Blog.Author>
            ‍<br/>
            <br/>
            <Blog.Text><i>Professor Peter Blamey is a well-respected hearing scientist advocate for hearing health, inventor and entrepreneur in Australia and internationally. We are joining him today at his office of his award winning profit-for-purpose hearing aid company Blamey Saunders hears located in East Melbourne. He has received multiple accolades for his revolutionary work and has published over 200 scientific papers and is a co-inventor of over 25 patient families.</i></Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>IT’S A PROBLEM THAT ONCE YOU HAVE A HEARING LOSS YOU’VE GOT IT FOR THE REST OF YOUR LIFE</Blog.Subtitle>
            <Blog.Text>There isn’t a cure at the moment but people are working on being able to regrow the hair cells inside the ear that are the most common cause of hearing loss – that’s still a long way off. The biggest problem that we can tackle is that there is a reluctance in people that have hearing loss to do anything about it; It’s much easier and cheaper to get your wife and children to talk louder so that you can hear them. The reason that we call ourselves a profit-for-purpose company is that we don’t want people to have to rely on a charity. It’s fair for them to pay for a solution to their hearing loss. However we don’t exist just to make a profit, we funnel those profits into researching better solutions and growing the company to provide better and more affordable solutions.</Blog.Text>
            <Blog.Text>In my opinion hearing loss is more of a social problem than a medical problem, but it’s treated more like a medical problem. That is one of the reasons why it’s expensive. The government realised this quite a long time ago and so there’s a government scheme to provide free hearing aids to people who can’t afford them. However that only helps out about half of the people that use hearing aids; there’s still 75% of hearing loss victims who should be using hearing aids who aren’t using them at all and aren’t addressed by the government scheme.</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>HEARING AIDS SHOULD BECOME ATTRACTIVE ACCESSORIES</Blog.Subtitle>
            <Blog.Text>People have a natural reluctance to come and see an audiologist in a clinic because it’s admitting, “I’ve got a clinical problem and I’m looking for a clinical solution.” Actually wearing hearing aids is something that ought to be as accepted as wearing glasses. I notice Amber is wearing glasses but nobody thinks Amber has got a disability. I wear glasses as well. I'd really like to see hearing as accepted in the same way that glasses are accepted. One of the big problems is that hearing aids are a lot more expensive than glasses.</Blog.Text>
            <Blog.Text>There are some initiatives in other countries to make hearing aids cheaper and to make them available over the counter than requiring you to see an audiologist. I think that’s a move in the right direction provided that the hearing aids can actually be fitted properly to the person. It’s not quite the same as going to the optometrist and trying five different pairs of glasses until you find the ones that work the best. It’s not as easy to tell which hearing aids are going to fit the best and you need to try a lot more than five different types to do that properly. That’s where our speech perception test and our method of helping people to fit their hearing aids themselves may be useful.</Blog.Text>
            <Blog.Text>We have tried to address the problem of the size and shape of hearing aids. It’s not an easy to get somebody to actually like their hearing aid – the shape and look of it – to the point where they’re happy to tell other people that they are wearing hearing aids. We have come some way towards addressing that. That hearing aid you see on the picture on the wall is called the Facett and it was designed here in Melbourne by Leah Heiss who calls herself a medical device jewelry designer. We’re trying to change the perception of hearing aids – we want to become an attractive accessory that you put on daily. We would like people to say, “That’s a nice hearing aid that you’re wearing” just as they might compliment the frames of your glasses.</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>IN THE FUTURE PEOPLE MAY BE ABLE TO USE THEIR HEARING AIDS TO CONTROL HOME APPLIANCES OR MAKE PHONE CALLS</Blog.Subtitle>
            <Blog.Text>The big trend nowadays is that hearing aids are increasingly becoming connected to the Internet, either through your phone or a Bluetooth connection. This opens up a new realm of possibilities – in the future people may be able to use their hearing aid to control home appliances or make phone calls. Unfortunately from my point of view that will make hearing aids more expensive because once they start to have phone functionality it’s adding a lot to the device complexity. There are also increasingly more devices that can feed information into a hearing aid via a wireless link. An example is a university lecture and you’re sitting at the back of the class, you can place a microphone close to the lecturer and have your own private wireless signal going direct to your hearing aids.</Blog.Text>
            <Blog.Text>A hearing aid is not just a sound amplifier. Hearing aids these days have directional microphones built in, and have noise reduction algorithms to make them less susceptible to wind noise and those sorts of things. The amplification is the primary function but being able to reprogram them or select different settings for different conditions is now standard. It’s like a small computer and the impressive part is that the small computer runs for a week on a battery the size of your fingernail. I don’t think there’s another computer like that anywhere in the world.</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>PEOPLE CAN DIAGNOSE THEIR HEARING LOSS EARLY USING A FIVE-MINUTE ONLINE TEST</Blog.Subtitle>
            <Blog.Text>I’d love everybody to do hearing tests at home: You can do it as many times as you like, and you have a record of what your hearing was like on a particular day. There’s 32 different lists of words and they’re presented in a random order. You’re never going to be able to memorize the word sequence and hence cheat the test. The test tells you how many words you got correct and how many words you got wrong – it’s different from usual hearing test. This test will tell you how serious your hearing deficiencies are and you can decide whether you want to get a hearing aid and whether it will help you. I think it’s good because it helps people understand how their hearing loss might be affecting their lives.</Blog.Text>
            <Blog.Text>People can now do their initial hearing test from the comfort of their own homes. They just have to go to the website and do a five-minute test. It’s not a big investment of time. What I hope is that people will take advantage of that and learn about their hearing loss sooner. For many years the people coming in to see audiologists in audiology clinics had had a serious hearing loss for 5-10 years or even longer before they did anything about it. That’s too long. And if you leave it untreated for too long your brain actually starts to deteriorate. You are not interacting with and understand your environment as much as you were when you had good hearing. You can respond to that in lots of different ways, often emotionally through depression and social isolation, and those things are not just bad for your hearing but also your health and quality of life. The real reason why you should do something about it sooner rather than later is so that you don’t have to put yourself in those awkward positions where you have to be asking people all the time “What did you say?” You should be happy to go and talk to people in noisy environments. It shouldn’t be impossible: you just need to do something about your hearing loss as soon as you notice a slight difference.</Blog.Text>
            <Blog.Text>The online test should work for people who are seven years or more. The reason is that it uses real words and children younger than seven won’t know all these words. In any case very young children require different therapies. I wouldn’t the test for people who are younger than seven, however if they already have hearing aids they can probably use this test to see for themselves how well their hearing aids are working.</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>ON MEDICAL PROFESSIONALS NOT ENCOURAGING PEOPLE TO BUY HEARING AIDS ONLINE</Blog.Subtitle>
            <Blog.Text>The medical profession routinely refer people to audiology clinics, including to Blamey Saunders hears. They don’t encourage people to buy hearing aids online - telling them that you may end up damaging your hearing and you won’t get the best result. That advice actually runs counter to my opinion that you can actually do better by looking after your own hearing rather than going to another professional and letting them do it for you. Mostly because you’re the one who knows what you are hearing. If you have always be explaining to somebody else what you are hearing so that they can adjust your hearing aid, it might take a long time to tune your hearing aids to the right point.</Blog.Text>
            <Blog.Text>The medical profession routinely refer people to audiology clinics and those sorts of things. We have had some referred people to Blamey and Saunders. I’m not quite sure how to put this but I guess they don’t encourage people to buy hearing aids online. Usually medically trained people say you shouldn’t do that and that’s a really bad thing to do. You end up damaging your hearing and all that sort of thing and you won’t get the best help. Those views actually run counter to my opinion in that you can actually do better by looking after your hearing yourself than by going to another professional and letting them do it for you. Mostly because you’re the one that knows what you’re hearing. If you have to explain that to somebody else so that they can adjust your hearing aid depending on how good you are in explaining it and how good they are in understanding might take you a long time to get to the right point.</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>HOW THE HEARING AID COMMERCIALS ARE MISGUIDED</Blog.Subtitle>
            <Blog.Text>A lot of hearing aid marketing material is orientated towards “come spend your money and this will solve your problem.” I don’t think that is the right approach. What Blamey Saunders hears would also do would visit a lot of community clubs and explain hearing loss, how a hearing aid can help and what their options are. We had a good response from doing that. It means actually going out and engaging with the community and not just advertising.</Blog.Text>
            <Blog.Text>I think empowerment and self-help are crucial parts of the message. If you think about how mobile phones have taken over the world it’s not because they are particularly cheap, but because people want to use them and understand they are missing out by not using them. You also have multiple payment options: if everyone had to cough up $500-$1000 when they bought a new phone many people couldn’t afford it. So asking people to cough up several thousands of dollars when you need a new pair of hearing aids is not the right model. I would really like to see a pay-as-you-go model for hearing aids: if people could pay $100 per month for three years rather than $3000 on the knocker, I think they would be a lot happier and they could stop paying if they stopped using their hearing aids. There are lots of things we can do to encourage people to use hearing aids.</Blog.Text>
            <Blog.Text>Hearing aids provide a service. It’s not just a fancy device you put behind your ear and forget about. If you’re not actually getting the improved hearing then you shouldn’t be paying for it, and to make people pay for it upfront is a bit weird. Paying the bundled fee for a hearing aid and additional service – whether the customer needs it or not - is not quite right. The price should come down if you don’t need help.</Blog.Text>
            <br/>
            <br/>
        </Blog.TextBlock>
    )
}