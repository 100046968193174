import React from 'react';
import { Blog } from '../../components';

export default function matthew_chan() {
    return (
        <Blog.TextBlock>
            <Blog.Author>Interviewed and written by Jefferson Tang</Blog.Author>
            ‍<br/>
            <br/>
            <Blog.Text><i>Matthew is a recent Monash Medical School graduate who while working as a doctor, has been involved in a bunch of medical technology and music projects: HorusAI - a medtech startup creating digital health tools for low-resource settings, symphony orchestras and DJ decks. Just recently he has been accepted for the one-year MBA programme at INSEAD in France, one of the most recognised business schools Europe-wide and indeed worldwide.</i></Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>IT IS ALL ABOUT BEING OPEN TO NEW PEOPLE, EXPERIENCES AND PERSPECTIVES</Blog.Subtitle>
            <Blog.Text>Working in hospitals, you meet doctors and patients from many different backgrounds. It’s all about breaking down cultural barriers and being open to new people, experiences and perspectives. The same applies when you’re studying abroad, especially in this international degree where you meet different people from different backgrounds who have diverse perspectives. It’s really important that you can approach every experience with an open mind and make the most of every opportunity to learn from everyone else.</Blog.Text>
            <Blog.Text>There is no way I could do a job where I was sitting in an office by myself all day. One of my biggest passions is  working on interesting projects in teams, often friends. Being able to sit down with a friend and understand what they are thinking and what makes them tick, and acting as the glue for a team and helping the team to function as smoothly as possible, is something that really interests me. An accelerated form of that dynamic is present in a hackathon. The MedHack 2016 hackathon which you participated in was a way to bring together teams of people from different disciplines who hadn’t previously met each other to collaborate on healthcare problems. That’s one of the most intellectually stimulating experiences for me - meeting new people to work on a new problem, where everyone brings something different to the table.</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>ON PURSUING AN INTERNATIONAL MBA</Blog.Subtitle>
            <Blog.Text>As I was wrapping up medical school, I started thinking about what I wanted to do later. I really enjoyed working in clinical medicine and seeing patients, however my experience with medical innovation inspired me to try something a little different. So I looked at various MBA programs - particularly overseas - and came across the INSEAD one-year MBA course in France that really excited me. It’s well known for being focused on delivering social impact through business and being a very international business school. From memory the last cohort included about 94 different nationalities. The idea of meeting many different people from all over the world really excited me. It’s also been a stroke of good timing - I have just finished two years of hospital medical practice and I haven’t joined a specialty program yet. I have got my general registration so it’s a good time to take a year off, regroup and see where I want to go to from here.</Blog.Text>
            <Blog.Text>In the long term, I see myself pursuing a career in healthcare innovation. In clinical medicine you make a lot of impact on individual patients and their families and that can be really rewarding. But for me I’ve always wanted to have a wider impact through the work that I do. Working in teams on long-term projects in addition to working on my traditional career has always really excited me. I think my medical degree and experience help me to understand the healthcare landscape, and my MBA will empower me with entrepreneurial and management skills to lead teams in healthcare innovation.</Blog.Text>
            <Blog.Text>There are so many different possibilities with what we can do with the MBA and that makes it very exciting. For me I know that I've come from the healthcare industry, and I know that I want to go back to the healthcare industry. It's really just about what kind of role I want to play. Realistically there are two main areas I could end up: number one is working in healthcare innovation potentially in a business strategy position, alternatively it would be great to step into healthcare management.</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>MASSIVE OPEN ONLINE COURSES (MOOCS) ARE A GREAT AVENUE TO PICK UP NEW SKILLS</Blog.Subtitle>
            <Blog.Text>When you’re going through medical school, the concept of lifelong learning and maintaining strong self-education habits is preached ad nauseam as being critical for a successful medical career. That line of thinking carries over to websites like Coursera and edX. These Massive Open Online Courses (MOOCs) are widely available and provide a really good opportunity for people to pick up new skills and to get a taste for topics, careers and industries that they wouldn’t be exposed to through their day-to-day work. They are really good means to decentralise high-quality education and make it a lot more accessible. I’ve personally done courses in machine learning, data science, maths, global health and languages. I think it’s a good way to interact with people who are normally outside your professional sphere.</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>ON FINDING AND COMMITTING TO EXCITING EXTRACURRICULAR OPPORTUNITIES</Blog.Subtitle>
            <Blog.Text>Some of them came by chance and some by design. Most medical school programs have limited or no exposure to important pockets of the healthcare world such as medical innovation, medical devices, the pharmaceutical industry and global health. It’s understandable that medical schools tend to focus less on these realms, but it can be a bit of a shame that young students aren’t exposed to more diverse opportunities.</Blog.Text>
            <Blog.Text>It’s really by chance that I found out about all these different medical innovation opportunities in Australia: through both my medical research job, and then also because some friends and I decided that we wanted to work on a project we were really passionate about - the digital health project in malaria diagnostics.</Blog.Text>
            <Blog.Text>A question that a lot of people have asked me is if I have ever worried about extracurricular activities impacting on my day-to-day roles as a medical student or junior doctor - my answer is usually no. Medical school grades and academic isn’t everything; having a good breadth of experience in understanding how people work and being able to work well in teams is infinitely more important than knowing a set of specific facts, especially in a world where we have instantaneous access to decentralised databases like UpToDate.</Blog.Text>
            <Blog.Text>Having a good balance between the four pillars of academics, social life, extracurriculars and sleep is difficult but it’s possible. It’s not something that I manage to get right all the time. In this regard I look up to one of my mentors who I have been fortunate to have for the last seven years, Rinaldo Bellomo. He is one of the most published doctors of all time in Australia and New Zealand, and he is real shining example of how you can balance it really well and have a good family life, good academic life and good professional life.</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>THE IMPORTANCE OF BEING OPEN-MINDED</Blog.Subtitle>
            <Blog.Text>I think it’s very easy for doctors sailing into a hierarchical work environment to become close-minded in two main ways.</Blog.Text>
            <Blog.Text>The first is in terms of career pathway: a commonly-held view is that it is a conveyor belt from medical school to residency to specialty training to fellowship to professorship. Medicine is so much broader than that, and there are so many other options: being a clinician-researcher, global health, public health, ethics, medical administration and management to name a few. Those options all use skills and knowledge learnt in medical school.</Blog.Text>
            <Blog.Text>The second is the way you perceive the workplace hierarchy in hospitals. Classically there are set roles: the students are at the bottom of the pile, the junior doctors are in the middle, and then the registrars and consultants are heaped on top. I think it’s really important to keep in mind that everyone has something to learn from each other. Once you become an intern you find out that the nurses are actually very friendly and helpful people who have the same goals as you and who you work closely with. Asking for their advice and learning from them is key, because many of them have more practical experience than we do as brand new doctors. One of the key tips that I tell my medical students is to learn everyone’s names - that’s incredibly important.</Blog.Text>
            <br/>
            <br/>
        </Blog.TextBlock>
    )
}