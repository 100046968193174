import React from 'react';
import { Blog } from '../../components';

export default function exam_edition_2() {
    return (
        <Blog.TextBlock>
            <Blog.Author>Interviewed and written by Jefferson Tang</Blog.Author>
            <br/>
            <br/>
            <Blog.Text>Meet Emily Warton and Daniel Hynes, two friendly Victorian doctors who have recently graduated from Monash University and the University of Melbourne respectively 👩‍🎓👨‍🎓. Emily is currently a medical intern with Monash Health, and <b>DANIEL:</b> is a general medicine registrar with Eastern Health👩‍⚕️👨‍⚕️. They have gathered to share their best tips on how to tackle the impending challenges of the end-of-year medical school written exams and OSCEs🤓, and engage in some wholesome banter along the way 😜</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>6️⃣ What can you be proud of the younger medical student self for, and what foolishness would you smack your younger self’s head for?</Blog.Subtitle>
            <Blog.Text><b>EMILY:</b> I wish I went to Macca’s less. I’m sympathetic to the forces that were pulling me towards Macca’s and away from studying. We would regularly drive out of town 15-minutes away from the student accommodation to visit the local Macca’s, actually one time we visited that Macca’s three times in one day. I regret doing that. But I did start an exercise regime for the first time in my life that year, which was good. I could give myself a gold star for that.</Blog.Text>
            <Blog.Text>I wish I studied more, especially in the years where people said, ‘this year doesn’t count.’ If you treat those years like they ‘don’t count’, you then have a shaky foundation for the following year. You should just study all the way through.</Blog.Text>
            <Blog.Text><b>DANIEL:</b>: I guess it’s always easier to find things to criticise yourself for, rather than things to pat yourself on the back for. I’m struggling to think of anything to pat myself on the back for. I guess I did a good job turning up to lectures.</Blog.Text>
            <Blog.Text>General advice would be, don’t treat med school like it’s some finite thing where you’re going to get to the end of it, stop studying, and everything will be fine and rosy. Now is the time to start building those study habits, because you’re going to be studying for the rest of your medical career.</Blog.Text>
            <Blog.Text>A good habit to get into is to regularly sit down after a ward round and go ‘these are the things I didn’t know, I’m going to go and look them up. I’m not going to do it tomorrow. I’m going to do it tonight and I’m going to make sure I understand it. Then tomorrow I’ll look back and double check that I remember it.’</Blog.Text>
            <Blog.Text>I think the big trick to winning in med school and beyond is just being time effective. Ideally you would regiment time for study, time for exercise, time for extracurricular activities - it can be volunteering for some outreach group, or being a research assistant, anything you’re interested in and can fit time for. You can also fit in time to get really hammered or whatever floats your boat.</Blog.Text>
            <Blog.Text>If you start budgeting your time now, you’ll find that when things get busy you’ve already got those habits on autopilot. Because if you play your cards well, you can come out of medical school with a fantastic hand: some useful connections with senior clinicians, a few research papers in the pipeline, and a start on something exciting like a podcast. Or you could study really hard, get an amazing Z score that becomes redundant as soon as you get your intern job, and have a blank CV. Those are the two different directions in which you can go.</Blog.Text>
            <Blog.Text>My advice is, don’t do what <b>DANIEL:</b> did which was get an average score and nothing on the CV. Try and spread yourself around a little bit, and be organised.</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>7️⃣ How do you deal with working full-time and studying for exams at the same time?</Blog.Subtitle>
            <Blog.Text><b>DANIEL:</b>: You have got to actually get joy out of learning new and interesting things all the time, and you have to fit the rest of your life around it. Even once you get to the other side of your current exams, it doesn’t stop. It’s not like you are finished with your exams once and for all, and the medicine you need to learn never progresses beyond there. There are always new things that you have to learn and know, and you always have to allocate a bit of of your personal time to study, as well as to other medical things, and of course to your friends, family and own wellbeing. None of that really changes throughout your career, maybe just the relative ratios.</Blog.Text>
            <Blog.Text>This will surprise you, but medical school is probably the least busy you will be throughout your career. If you can smash out some of the things now that you would have to do later on in your career anyway, then you're playing the system very well. Because you can either work on those research projects now, or you can work on them while you’re doing 50 hour work-weeks.</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>8️⃣ How do you take care of your health and relationships during the exam lockdown period, and also in times when you have lot of things on your plate?</Blog.Subtitle>
            <Blog.Text><b>EMILY:</b> Don’t take out your stress on anyone else.</Blog.Text>
            <Blog.Text><b>DANIEL:</b>: This is where time budgeting is important. Putting yourself into an absolute lockdown mode and never seeing your friends, family or loved one is not healthy. You will actually do worse in the long run if you do that. You need a break every so often. If you find that you keep pausing to play Dota (or some other video game or procrastination activity), it means that you’re a bit cooked (from all the study) and maybe you need to study a bit less and spend a bit more time with your loved ones. Make your study time count and make your spare time count.</Blog.Text>
            <Blog.Text>Don’t lose your temper at people you care about if you’re having a bad time from the exam study. Bite your tongue for a second, take a deep breath, and tell them ‘sorry I’m very stressed and these are the reasons.’</Blog.Text>
            <Blog.Text><b>EMILY:</b> People will understand. You’re doing medical school and you’re going through some intense exams. They will understand that you won’t be present very much. Just don’t get carried away with the momentum of how stressed you are and let it negatively impact your relationships. Always feel free to share your feelings with other people because that’s the best thing you can do.</Blog.Text>
            <Blog.Text><b>DANIEL:</b>: It took me a long time to actually believe that regular exercise and keeping fit will help you be much more sharp on the actual exam day. It also makes a massive difference to how effectively you study. It’s very tempting to say no, I’ll put in my five hours of study and the length of time I spend studying is what matters. But in reality, if you go for a run and then sit down to study afterwards, you’ll make the hour-long (or however long) study session count for so much more. The mental crispness you get just after exercise helps you make leaps in your exam study journey, whereas you’ll be like a cripple hobbling on crutches on your exam study journey without regular exercise.</Blog.Text>
            <Blog.Text>The exams never stop and everything only gets harder once you start working. You’ll burn out quickly if you sacrifice your health and relationships for the sake of exam study.</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>9️⃣ If your results end up being worse than anticipated, or if you end up failing, what is the best way to cope and respond?</Blog.Subtitle>
            <Blog.Text><b>EMILY:</b> I think talking to someone about it is the starting point. Trying to figure out if you fail, how can you sort it out and move forward with your goals. Hopefully the university is supportive of your situation and they’ll give you the information early on. It’s quite structured and you don’t have to fly around trying to figure out things for yourself. And try not to take it too personally.</Blog.Text>
            <Blog.Text><b>DANIEL:</b>: Everyone always stresses about failing uni exams. However there is a strong financial incentive for the university to pass you. You are probably going to end up all right.</Blog.Text>
            <Blog.Text>In the off chance that you fail, it’s a game. Don’t take it personally because there is no such thing as a perfect exam where you are fairly assessed on how good you are as a medical student or as a doctor or whatever.</Blog.Text>
            <Blog.Text>Have people you can talk to about it; people that you can trust.</Blog.Text>
            <Blog.Text>You can go one of two ways after you suffer a ‘exam failure’: you can either go on a big negative spiral of ‘I’ve had a shit time so I’m going to get really blind drunk for a while and I’m not going to do much. Maybe I’ll tell my girlfriend that she ruined everything and it’s her fault. Or you can go the other way and say, ‘All right, I failed and I feel really inadequate about how good I am. So I’m going to study even harder, I’m going to go for that run, I’m going to eat really well and I’m going to sleep as well as I can. The only way to get over feeling inadequate about failing an exam is to do some things that make you feel like you’re a bit of a winner.</Blog.Text>
            <Blog.Text><b>EMILY:</b> People fail all the time in every domain of life. Especially in medical school when you’ve got these exams, you just focus on the next thing in front of you and you’re not seeing the hundreds of things behind you. Everyone around you has failed in multiple things.</Blog.Text>
            <Blog.Text>Your seniors have failed. If you fail now, it will mean that you take something from the experience and you won’t fail in the future where your peers will.</Blog.Text>
            <Blog.Text><b>DANIEL:</b>: It’s character building. In the b=ig scheme of things, even if you’re an ‘old’ Melbourne postgrad, I promise that by the time you’re a consultant, you’ll still be super young if you just blast through (the whole training path) without ever stopping. Once you hit that point, there’s nowhere else to go. So don’t rush. Take your time. Learn what you need to learn, make the mistakes you need to make and learn from them. Don’t feel too bad about it, it’s all a part of life.</Blog.Text>
            <Blog.Text><b>EMILY:</b> I think the hard and scary thing for a lot of people is other people finding out that they failed. Your one exam failure doesn’t reflect on you as a person. I think it’s all about keeping perspective that people fail all the time and this is just a single event. In the future, if one of your peers fails, they will hold themselves completely accountable for it. They won’t be thinking of your failure, they will instead blow their own failure out of proportions. Your failure is just a blip in everyone else’s mind, and is really massive to you. Don’t worry too much about what other people are thinking about you, because it’s always so much more important to you than it is to them.</Blog.Text>
            <Blog.Text><b>DANIEL:</b>: It’s super true, we’re all terrible at dealing with failure. We all define our ideas of whether we are successful people or not a bit too much by how we are doing in medicine, which is not entirely valid. You need to keep in mind that although you might not have passed an exam, you need to look at the broader picture and think about all the other things in life that you’re winning at.</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>🔟 What pleasures can you allow yourself during the exam lockdown period?</Blog.Subtitle>
            <Blog.Text><b>DANIEL:</b>: Enjoy the things that are good for you as much as you can. Enjoy delicious, succulent meals. Enjoy sleeping, and enjoy exercise - actually enjoy it, it shouldn’t be a chore.</Blog.Text>
            <Blog.Text>You can still allow yourself some time to have fun hanging out with your mates. Again, do everything in moderation. Just make sure you’re organising your time well so that you can fit all those things in. Obviously, don’t get completely hammered the night before your exams.</Blog.Text>
            <Blog.Text>Just nothing in excess really is the key. You can allow yourself lots of pleasure, just not in excess.</Blog.Text>
            <br/>
            <br/>
        </Blog.TextBlock>
    )
}