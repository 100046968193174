import React from 'react';
import { Blog } from '../../components';

export default function jonathan_king() {
    return (
        <Blog.TextBlock>
            <Blog.Author>Interviewed and written by Sarah Jackson</Blog.Author>
            ‍<br/>
            <br/>
            <Blog.Subtitle>So if you think doctors have very translatable skills, do you think they are more able to start a business and be an entrepreneur than most?</Blog.Subtitle>
            <Blog.Text>No, I don't think that. I think that doctors though should never doubt themselves and they can achieve what they want if they put the time in. But anyone can do that. If you put in enough time and you learn and absorb enough, even if it takes months, you can learn what you're meant to do. At the end of the day if you have the means to surround yourself with the right people who are in that skill set, you can set yourself up well. They always talk in entrepreneurship never be the smartest person in the room. I'm very fortunate to surround myself with people who are much smarter than me, and this is how the company grows and can lead to progress. Really, I think being a doctor we actually have to work in teams a lot more than people expect or think of us. I think that we actually show points of leadership by handling delicate situations, communicating with the patient and their family; as well as the nurses and other surrounding staff. Don't underestimate yourself, because it does take leadership to do that. When people earlier on asked me, do you even know how to run a team? Actually we do, we actually run significantly large teams. Being able to be on call for a hospital with X number of beds is actually a lot more stressful than being a CEO or an operations manager!</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>How do you see the change that Lysn creates in the mental health sphere?</Blog.Subtitle>
            <Blog.Text>We see it in all our services, as people can give feedback afterwards. It's not as common as seeing someone day-to-day in the hospital and giving you feedback, but you might get an email thanking you or saying something like ‘I’ve been been looking for someone for nine months to get my partner to come to couples counsel. Now 18 months later there's no excuses because we can do it at home so easily at 9 p.m. at night”. Most of our services are after hours. Peak times are 2pm to 10pm. We really can provide services that fit people in the time of day that they need, so it's not like you're leaving work during the day to have to see someone. Clinicians don't want to be on call 24/7 either. How can we expect Sydney clinicians to be starting at 8 o'clock in the morning then staying up till 11 seeing patients? For us, facing this issue we thought: let's go to WA and recruit. We went over to WA and recruited 30 plus psychologists because that we knew that we could utilize their time difference in order to push our hours of service later. Using technology allows us to do things like that.</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>How do you go about your days in the business world?</Blog.Subtitle>
            <Blog.Text>On a day to day we usually have a little team meeting in the morning, understanding what our goals are for the week. We usually have two meetings a week, so the whole team is on the same page and then individual people on the team have different roles. My head-of-clinical is psychologist and we work together very closely planning all the main meetings. We could have six meetings in a day. Some of them are on phone or online, some of them are in the city. We really try to structure it so we can go to meetings efficiently. We might be even be doing a phone meeting while we are in an Uber or Taxi to the next meeting. We've done that a few times! The meetings are all really different. Like if you're meeting tech developers, you could be being advised on how to implement security, wheras some meetings are purely business development. The last few weeks we've been focusing on business development, so we're meeting government agencies or clinics, and then going on to do training. Or we're meeting with lawyers to make sure that we have the contracts correctly written up. Entrepreneurship really will test every avenue of your brain or your skill set and make you learn more.</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>How is Lysn founded? Do you get government funding?</Blog.Subtitle>
            <Blog.Text>Jonathan: Lysn was bootstrapped off my savings, then followed by selling my apartment, and now we continue to bootstrap off revenue. We are definitely going to have to get funding from investors, but that will be in due time. You have to really try to think as far as possible into the future, because the reality is when you're funding it yourself, you can direct your choices towards what you want to achieve. You can take on investors, but make sure that the investors are the right people who actually believe in your business, and not just trying to commercialize. Yes, the aim is to make it a sustainable business, but in healthcare it's not like you're selling a product or renting out your house. Your aim is to achieve certain outcomes. I think that sometimes can be lost. Really keeping bootstrapped and working tightly as a team has allowed us to not lose focus.</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>What do you think of the mental health issues in the medical community itself, as a separate issue? Do you think that needs to be another service that's available to doctors, health workers and students as well?</Blog.Subtitle>
            <Blog.Text>We are about to launch a partnership which I can't talk about today. Very soon though, with a large medical organization doing junior doctor support. I think that there's a lot of factors in what's affecting doctors. Going through that, just the sheer hours you have to work, being on call, and never having downtime when you're on call. Let's say you are on call for sub specialty surgery for 72 hours. From Friday afternoon all the way until Monday morning, that can be very busy. I think there's a lot of stresses, and I think doctors and nurses are phenomenal people. You have to deal with the stresses of the health care system and constantly giving yourself and helping people all the time, and you may not really be getting that much self-care back. That's one of the things you have to deal with as a doctor, and I think there needs to be implementation of safety measures into the healthcare system for workers to protect doctors and other health professionals. The second thing is for doctors and for all health professionals to really understand their rights. I've spoken to both specialists and junior doctors, and some of them think that speaking to a psychologist or seeking help in that capacity means that the psychologist has to name and report them; and that's not the case. The case is, if you're potentially at risk of affecting a patient's care, then they have to report you. If you're just suffering from depression, anxiety or imposter syndrome, it’s a completely different story. For me in business especially I'm going through imposter syndrome all the time, wondering if I have the knowledge to get through this. I think you just have to understand that it is natural. You need to get help and support otherwise you'll continue to just wither, and those thoughts will inevitably prevent you from getting better.</Blog.Text>
            <br/>
            <br/>
        </Blog.TextBlock>
    )
}