import React from 'react';
import { Blog } from '../../components';

export default function anita_elias() {
    return (
        <Blog.TextBlock>
            <Blog.Author>Written by Erica Musgrove</Blog.Author>
            ‍<br/>
            <br/>
            <Blog.Subtitle>How did you get into this niche area (of medicine), and what originally engaged you in the topic of sexual health and therapy?</Blog.Subtitle>
            <Blog.Text>In those days it (medical school) was three years of university and three years of hospital, so the first three years were all anatomy biochemistry, all that stuff, the science of it. But we actually had a whole week on sex in second year, and I'm talking in the 70s, and we had a speaker called Elsie Koadlow who was working as a GP and she did a lot of family planning. People came to her with sexual problems, and she realized there was no one working in that area and she saw the big need. She actually went to London and trained in psychosexual medicine and came back and started the first clinic which was at the Queen Vic at that time, which has now become Monash (Sexual Medicine & Therapy Clinic).</Blog.Text>
            <Blog.Text>She spoke, and what really impressed me was when she was talking about ‘gynae’ examination and she said that if you're doing a gynecological examination then you've got to remember that that is a woman lying there, with thoughts and feelings. I guess that was my first introduction to the mind-body connection. Plus, just the way she spoke, I thought she was fantastic, and I thought at that point ‘I want to be like Elsie when I grow up’.</Blog.Text>
            <Blog.Text>In fact, in my final year we had a magazine and we were asked what you want to be doing in 10 years time. I said I wanted to be taking over her clinic. Now, it took me 20 years, but the Sexual Medicine & Therapy Clinic at Monash is the one that she started in a different iteration. But I had a circuitous route, when I graduated there wasn't any direct route. I actually rang her and said, “What do you think I should do?”</Blog.Text>
            <Blog.Text>After the early hospital years, I did a couple of years of psychiatry training and then I went into general practice. I was always very interested in the mind-body connection, and so even as a GP I enjoyed the counselling part the most. I became very interested in holistic health, looking at the whole person. I did training around 30 years ago in meditation, before it was fashionable.</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>Can you tell us a little bit about the kind of work you're doing at the moment.</Blog.Subtitle>
            <Blog.Text>Okay, so I work in several places but it's all specializing in sexual medicine and sex therapy. Firstly, where we are at the moment, The Women's (Hospital), and at a sexual counselling clinic at Monash, called the Sexual Medicine and Therapy Clinic. I have a private practice and I'm also employed by Monash Uni, in the department of O&G. I'm seeing people with sexual difficulties, as the reason for the referral. They present with sexual difficulties, and I see them to work out what's going on for them.</Blog.Text>
            <Blog.Text>I'm (also) currently working at Monash Uni developing a 2-day short course that's being held in April actually. It’s called Let's Talk About Sex: Sexuality & Sexual Difficulties. Day one we'll be looking at sexuality in general, and day two we'll be looking at sexual difficulties. It's multidisciplinary with the 11 speakers we've got, so that's very exciting. We've got some specialists like myself, so GPs who specialized in sexual medicine and sex therapy, and a sexual health physician. We've got psychologists, a sex and relationship therapist, a psychiatrist who is head of the Gender Clinic. We've got a physiotherapist, an endocrinologist and a gynaecologist. There will be very multidisciplinary speakers and audience. It's open for all doctors, GPs and specialists, and psychologists, physiotherapists, any health practitioner.</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>What kind of sexual difficulties do you see most commonly?</Blog.Subtitle>
            <Blog.Text>Well, at The Women's we only see women, so the most common presenting complaints are painful sex or a lack of interest in sex, or lack of libido. Sometimes women with difficulty with arousal or orgasm. At Monash we see women with those problems, but also men with erectile dysfunction, premature ejaculation, or other ejaculatory problems, and libido issues as well.</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>What are some of the biggest factors that you think have an influence over sexual difficulties in your patients?</Blog.Subtitle>
            <Blog.Text>We've got to look at the whole picture. For any individual we've got a look at the physical issues that could be happening. For a woman with painful sex we've got to see if there's any gynecological problems, or vulval skin problems. Then, what's happening with the pelvic floor? But you can’t separate the mind and the body. I think when it comes to life in general you can't separate them mind and the body, but especially when it comes to sex. So, I want to know how the person feels about sex, how they think about sex, what's going on during it. Also, if they're in a sexual relationship, you have to look at what's going on between people. What's happening in the relationship? Because anything that's going on in the relationship can impact on sex.</Blog.Text>
            <Blog.Text>Then there's the even bigger picture. One of the things I did before getting into this area was family therapy training. It's amazing how often family influence has come into it, either what they've learnt growing up or the family effects now. Then you've got to look at an even bigger picture and that is the culture that they're living in, and particularly in the hospitals I work at where we have a huge multicultural population. It could be traditional cultures, or even the culture we're living in now, in Melbourne in 2019, can have a huge impact. Sometimes it's one end of the spectrum to the other, so we've got to look at all of that.</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>You were talking about this spectrum of sexuality and sexual interest, where some people might not want to have sex at all, and other people might want it a lot. How do you then discern someone with maybe an exaggerated sexuality or low libido, from someone being, say, asexual?</Blog.Subtitle>
            <Blog.Text>You really need to do a thorough assessment. The definition of asexual is never having any sexual attraction to anyone. Asexual people may want to have romantic relationships, or other kinds of relationships, but they don't want to be sexual. That's different to someone who has had sexual attractions, or someone who may have wanted to be sexual, although even with their sexuality I guess it can develop down the track.</Blog.Text>
            <Blog.Text>With someone who complains of a loss of libido, then a detailed history looking at how things have changed, when things have changed, and all the other factors. What I'm looking for is what might be getting in the way or what might be missing for them at the moment. It is important to take a full sexual history to see whether there's been a shift or a significant change. I often say to my patients that I see my job as Sherlock Holmes, looking for clues. I really get the story of their life, and I’m looking at it unfolding with the lens of how might this life be impacting on their sexuality. So, through doing that, you get a sense (of the issue). In that way, I think medicine is art and science.</Blog.Text>
            <br/>
            <br/>
            <Blog.Subtitle>Resources & More Info</Blog.Subtitle>
            <Blog.Text><a href="http://www.itstimewetalked.com.au/">http://www.itstimewetalked.com.au/</a></Blog.Text>
            <Blog.Text><a href="https://www.monash.edu/medicine/scs/ob-gyn/short-courses-for-professional-development/lets-talk-about-sex">https://www.monash.edu/medicine/scs/ob-gyn/short-courses-for-professional-development/lets-talk-about-sex</a></Blog.Text>
            <Blog.Text><a href="http://monashhealth.org/services/services-o-z-monash-health/sexual-medicine-and-therapy/">http://monashhealth.org/services/services-o-z-monash-health/sexual-medicine-and-therapy/</a></Blog.Text>
        </Blog.TextBlock>
    )
}