import React from 'react';
import { Blog } from '../../components';

export default function brian_spain() {
    return (
        <Blog.TextBlock>
            <Blog.Author>Interviewed and written by Jefferson Tang</Blog.Author>
            <br/>
            <br/>
            ‍<Blog.Text><i>Dr Brian Spain is the Director of Anaesthesia and Co-Director of Surgery and Critical Care at the Royal Darwin Hospital. He has been directly involved in providing medical care to victims of major natural and man-made disasters including the 2002 and 2005 Bali Bombings, Typhoon Haiyan which devastated the Philippines in 2013, and most recently the 2019 Samoa measles outbreak. He was awarded Member of the Order of Australia (OAM) in 2016 for significant service to medicine in the discipline of anaesthesia, as a clinician, to healthcare standards, and to professional medical bodies.</i></Blog.Text>
            <br/>
            <br/>
            <Blog.Author>BALI BOMBINGS – “IT WAS HORRIFIC. THERE WERE PEOPLE STACKED LITERALLY THREE HIGH AND FIVE DEEP.”</Blog.Author>
            <Blog.Text>In 2002 on the weekend that the Bali Bombings occurred, I was in Darwin co-running the first ever Anaesthesia Continuing Medical Education meeting. It was only on the Sunday morning tea break that the news got out that the Bali Bombing had happened and there was a plan to repatriate injured Australians to Darwin. So we finished off the meeting and rushed off to make preparations for receiving the victims at the Royal Darwin Hospital.</Blog.Text>  
            <Blog.Text>I was asked to coordinate the reception of the victims at Darwin Airport. We had every ambulance in the Northern Territory – 10 in total - waiting there at that time. The first victims arrived on a Hercules plane late at night, 26 hours after the bombings happened. It was horrific going into the back of the aircraft. There were people stacked literally three high and five deep. Seeing the horrific injuries, I just snapped into medical doctor mode and organized staff to take them into the ambulances and send them to the hospital where they were ferried through the emergency department. Four Hercules airplanes arrived over a 24-hour period and a total of 65 people came through Darwin Hospital.</Blog.Text>
            <Blog.Text>It was an incredible national medical response. Every state in Australia responded and within 48 hours of the whole thing starting it had finished. Every medical retrieval jet in Australia in 2002 made its way to Darwin and evacuated injured people back to hospitals in their own respective states.</Blog.Text>
            <br/>
            <br/>
            <Blog.Author>“TO BE HONEST, DISASTER MEDICINE IS MOSTLY NOT THAT COMPLICATED, UNLESS YOU’RE ACTUALLY IN THE MIDDLE OF A DISASTER WHEN IT HAPPENS.”</Blog.Author>
            <Blog.Text>By the time international teams get there, which will be three or four days if they are quick, most of the victims will either have had their surgery or will not have survived. Most of the work at this stage is wound care, some fracture work and there may be some complex plastic reconstruction work. In Typhoon Haiyan in the Philippines, there was a lot of wound care to do on people with infected diabetic foot wounds. It wasn’t complicated medically but it was complicated logistically.</Blog.Text>
            <Blog.Text>The living conditions were arduous: we were crowded into tents, and it was hot and humid and frequently pouring with rain. There was lots of testing of interpersonal relationships going on in such close and uncomfortable quarters. So when we select and train people, we are looking for people who are flexible and easy going.</Blog.Text>
            <Blog.Text>When I come back to Darwin the medicine is more complex and varied but we obviously have more resources. At my home hospital most people are hardworking but some are not as flexible as others. Most people working in field disasters have chosen to be there and find it really invigorating work.</Blog.Text>
            <Blog.Text>We’re also careful that we don’t take any trainees there. The feeling is that it’s not a training exercise, you’re there to deliver healthcare to the best of your ability. Some medical students and trainees get frustrated that they can’t get involved. You’ll be surprised at how quickly the years go by and then you’ll be delivering healthcare in a disaster zone with fully trained medical staff.</Blog.Text>
            <br/>
            <br/>
            <Blog.Author>THE GREAT THING ABOUT WORKING IN THE NORTHERN TERRITORY IS THAT THE MEDICINE IS JUST INCREDIBLY INTERESTING AND REALLY REWARDING</Blog.Author>
            <Blog.Text>When you work in rural locations you’ve got to deal with whatever comes through the door. You don’t have another hospital that you can just transfer people immediately to. You might transfer someone a few hours later after being stabilized. In Darwin we’re 3,000 kilometers away from the next referral hospital so we tend to deal even more with whatever comes through the door. It’s medically challenging but it keeps up a very broad range of skills.</Blog.Text>
            <Blog.Text>We have a large Aboriginal population – just under 30% of the population in the Northern Territory is Aboriginal. But in the acute hospital setting probably 50% of our patients are Aboriginal. Many of them live a very traditional life. You get exposed to amazing aspects of traditional Aboriginal culture that I just had no idea about when I lived in other parts of Australia. Most people who get an opportunity to do an elective here or work as a junior doctor find it incredibly interesting and really rewarding medicine, albeit hard work. It’s a great opportunity that you don’t really hear about when you’re living in the big cities.</Blog.Text>
            <br/>
            <br/>
            <Blog.Author>FROM WANTING TO BE A RURAL GP -> ANAESTHETICS AND HEALTHCARE MANAGEMENT</Blog.Author>
            <Blog.Text>When I finished medical school in Melbourne I thought I wanted to be a rural GP. Working in my resident years at Box Hill Hospital I loved emergency work so I then started on the path as an emergency medicine trainee. As part of that I had a one-year anaesthesia rotation to a hospital in a town called Taunton in the UK. I had no idea what anaesthesia was about before that, but I soon realized how amazing it was. I stayed on in the UK for another six months to do an obstetric rotation because I was still thinking about becoming a rural GP, however by the end of that rotation I realized that I really wanted to pursue anaesthesia as a career.</Blog.Text>
            <Blog.Text>I came back to Australia but didn’t really want to come back to Melbourne. I wanted to see and experience a different place so I got onto the anaesthetic training program in Perth where I did the first couple of years. My wife then finished her GP training and got a job in East Arnhem Land up in the Northern Territory. I took a year off the anaesthesia training program and we went and lived up there. We were up there for six months and then we went traveling for the next six months. After that I came back and finished anaesthesia training in Melbourne where my family was.</Blog.Text>
            <Blog.Text>By that time I realized that I wanted to work in a more remote place so I got a job in Darwin. I thought I was going to be up here for a year or two. That was back in 1997. It’s 2019 and I’m still here. That tells you what an awesome place it is to work.</Blog.Text>
            <Blog.Text>I have loved every day that I have worked in anaesthesia. People think you just put people to sleep and you don’t have much to do with people; you actually have a lot to do with people when you’re dealing with complex medicine. You’re working with the theatre team, and you also have many encounters with extremely anxious patients. Part of doing the job well is to allay their anxieties and explain what’s going to happen to them.</Blog.Text>
            <Blog.Text>We’re also getting increasingly involved in perioperative medicine: not just what happens in the operating theatre, but also optimizing their preoperative condition and caring for them postoperative. Anaesthesia involves pharmacology and physiology in action, mixed in with a lot of really interesting encounters with patients. I’ve also picked up intensive care skills and anaesthesia has given me the skills to work in the disaster setting.</Blog.Text>
            <Blog.Text>I have also gotten involved in healthcare management in my middle 30s. What I have learned is that if you become a manager of a good team, you can actually do more good for more people by looking after my team and ensuring my department has happy doctors.</Blog.Text>
            <Blog.Text>I love going to work every day and not quite knowing what is going to be coming around the corner.</Blog.Text>
            <br/>
            <br/>
            <Blog.Author>NO MATTER WHAT, KEEP YOUR PATIENTS AND THEIR FAMILIES AT THE CENTRE OF EVERYTHING YOU DO</Blog.Author>
            <Blog.Text>As a Director of Medical Services I talk to junior doctors frequently. It’s very easy to get a little disillusioned sometimes in the training system in public hospitals. I always tell junior doctors to just keep the patients and their families at the centre of whatever you do; Focus on looking after them and that will allow you to put up with all the other things that happen in the health system that you find frustrating.</Blog.Text>
            <Blog.Text>Particularly up here in the Northern Territory where you see so many disadvantaged Aboriginal people that have been subject to multigenerational oppression, racism and all sorts of terrible things. Knowing that we can each do a little bit to improve their lives has always been inspirational to me.</Blog.Text>
        </Blog.TextBlock>
    )
}